import { service } from "@/common/axios-service";
const state = {
    profile: null,
}

const getters={
  StateProfile: (state) => state.profile,
}

const actions ={
  async ProfileInfo({ commit }) {
    return new Promise((resolve,reject)=>{
      service.post("Student/Profile")
      .then((res) => {
        if(res && res.data){
          commit("setProfile", res.data);
          resolve();
        }
        else
          reject({message:''});
        
      })
      .catch(err => {
        reject(err);
      });
    });
  },

  async ProfileChangePasswordSave(context,form) {
    return new Promise((resolve,reject)=>{
      service.post("Student/ChangePassword",form)
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  
  async RegisterReferenceForm(context,form) {
    return new Promise((resolve,reject)=>{
      service.post("Student/RegisterReferenceForm",form)
      .then((res) => {
        if(res){
          resolve(res.data);
        }
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },


  async ClearProfile({ commit }) {
    let profile = null;
    commit("clearprofile", profile);
  },
}

const mutations ={
      setProfile(state, profile) {
        state.profile = profile;
      },
      clearprofile(state, profile) {
        state.profile = profile;
      },
}

export default{
    state,
    getters,
    actions,
    mutations
};
<template>
  <div id="app">
  <router-view />
 </div>
</template>
<script></script>
<style>
  .text-site-orange{
    color: #ffa020;
  }
  .bg-site-orange{
    color: #ffa020;
  }
  .bg-site-green{
    color: #4fb748;
  }

  .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
      background-color: #4fb748 !important;
      border-color: #4fb748 !important;
  }
  .badge-color-yellow{
    background-color: #ffa020;
  }

</style>

<template>
    <div class="d-flex align-items-center py-4">
      <div class="form-signin rounded-3 bg-white w-100 m-auto">
          <img src="../assets/images/lengedu.jpg" alt="">
          <div class="form-floating">
            <input type="email" class="form-control" id="email" v-model="form.username" placeholder="name@example.com" autocomplete="email">
            <label for="email">E-posta</label>
          </div>
          <div class="form-floating">
            <input type="password" class="form-control" id="password" v-model="form.password" @keyup.enter="submit()" placeholder="Password" autocomplete="new-password" >
            <label for="password">Parola</label>
          </div>

          <div class="form-check text-start my-3">
            <input class="form-check-input" type="checkbox" id="remember" v-model="form.rememberMe">
            <label class="form-check-label" for="remember">
              Beni Hatırla
            </label>
          </div>
          <button class="btn btn-primary w-100 py-2" type="button" @click="submit()" :disabled="form.isLoading">Giriş Yap</button>
          <div class="text-center mt-3">
            <router-link :to="{path:'/forgot-password'}" class="btn w-100 fw-bold">Parolanızı mı unuttunuz?</router-link>
            Hesabınız yok mu?<a href="https://www.lengedu.com/?utm_source=student.lengedu.com&utm_content=hesap-olustur-link" target="_blank" class="btn  fw-bold">Kayıt olun.</a>
          </div>
      </div>
    </div>
    <div><VersionNumber /></div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import { LoadingToast,UpdateToast,RemoveToast,ToastType } from "@/common/notification.js";
  import VersionNumber from "@/components/Shared/VersionNumber.vue";
  export default {
    name: "LoginPage",
    components: {
      VersionNumber
    },
    data() {
      return {
        form: {
          username: "",
          password: "",
          rememberMe:false,
          isLoading:false
        }
      };
    },
    methods: {
      ...mapActions(["LogIn"]),
      async submit() {
        let toastId = LoadingToast();
        this.form.isLoading=true;
        this.SaveLocalStorage();
        const user = {
          "UserName": this.form.username,
          "Password": this.form.password,
          "PanelType": 2
        };
        await this.LogIn(user)
        .then(()=>{
          this.form.isLoading=false;
          RemoveToast(toastId);
          this.$router.push("/");
        })
        .catch(err=>{
          UpdateToast(toastId,err.message,ToastType().ERROR);
          this.form.isLoading=false;
        });
      },
      SaveLocalStorage(){
        if (this.form.rememberMe) {
          localStorage.rememberUsername = this.form.username;
          localStorage.rememberCheck = true;
        } else {
          localStorage.rememberUsername = "";
          localStorage.rememberCheck = "";
        }
      },
      CheckRemember(){
        if (localStorage.rememberCheck && localStorage.rememberCheck !== "") {
          this.form.rememberMe = true;
          this.form.username = localStorage.rememberUsername;
        } else {
          this.form.rememberMe = false;
          this.form.username = '';
        }
      }
    },
    mounted: function() {
      this.CheckRemember();
    }
  };
  </script>
  
  <style scoped>
    .form-signin {
      max-width: 330px;
      padding: 1rem;
    }
    .form-signin img{
      margin: 0 auto 15px;
      display: block;
    }
    .form-signin .form-floating:focus-within {
      z-index: 2;
    }

    .form-signin input[type="email"] {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .form-signin input[type="password"] {
      margin-bottom: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  </style>
<template>
  <div class="d-flex align-items-center justify-content-center vh-100 bg-white">
      <div class="text-center">
          <img src="../assets/images/lengedu.jpg" style="width: auto;">
          <h1 class="display-1 fw-bold">404</h1>
          <p class="fs-3"> <span class="text-danger">Opps!</span> Sayfa bulunamadı.</p>
          <p class="lead">
              Aradığınız sayfa bulunamadı.
            </p>
          <router-link to="/" class="btn btn-primary">Ana sayfaya git</router-link>
      </div>
  </div>  
</template>
  <script>
  export default {
    name: "NotFound",
    components: {},
    data() {
      return {
      };
    },
    methods: {
    },
  };
  </script>
  <style scoped>
  </style>
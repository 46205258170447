<template>
  <div>
      <div class="row justify-content-center mt-3 mb-2">
          <div class="col-sm-12 col-md-3 col-lg-3 text-center">
             <div class="small-box bg-light bg-gradient border border-light-subtle p-2 rounded mb-1">
                  <div class=" fs-5"><i class="bi bi-stopwatch"></i> Süre</div>
                  <div class="fs-4" v-html="totalDuration"></div>
              </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3 text-center">
            <div class="small-box bg-light bg-gradient border border-light-subtle p-2 rounded mb-1">
                  <div class=" fs-5" ><i class="bi bi-check2"></i> Doğru Cevap</div>
                  <div class="fs-4" v-html="totalCorrect"></div>
              </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3 text-center">
              <div class="small-box bg-light bg-gradient border border-light-subtle p-2 rounded mb-1">
                  <div class=" fs-5" ><i class="bi bi-x"></i> Yanlış Cevap</div>
                  <div class="fs-4" v-html="totalInCorrect"></div>
              </div>
          </div>
          
      </div>
      <div class="text-center fs-5 mb-2" v-if="ShowLevel">Seviye test sonucunuz <span class="fs-2" v-html="GetLevelTestPuan(totalCorrect)"></span> olarak belirlenmiştir.</div>
      <div class="row small-box border border-light-subtle p-2 rounded mb-1" v-for="q in exercises" :key="q">
          <div class="col-lg-1 text-center"><h3 v-html="q.sequence+'.'"></h3></div>
          <div class="col-lg-6">
              <p v-html="q.question"></p>
          </div>
          <div class="col-lg-4">
              <div><span class="fw-light d-block fst-italic" style="margin-bottom: -10px;"><small>Cevabınız :</small></span><span class="text-primary fs-5 text-capitalize">{{GetUserAnswer(q)}}</span></div>
              <div class="fw-light d-block text-opacity-25"><small>Doğru Cevap : <span class="text-capitalize">{{GetRightAnswer(q)}}</span></small></div>
          </div>
          <div class="col-lg-1">
              <div class="fs-2 text-center text-success" v-if="IsSuccess(q)"><i class="bi bi-check2"></i></div>
              <div class="fs-2 text-center text-danger" v-else><i class="bi bi-x"></i></div>
          </div>
      </div>
  </div>
</template>
<script>
  import {GetLevelTextByCurrectPuan} from '@/common/test-helper';
  import {ConvertMiliSecoundToSecound} from '@/common/datetime-helper';
  export default {
    name: "ExcerciseTestResult2",
    props: {
      ExcerciseData:{
        type:Object,
      },
      ExcerciseUserAnswerData:{
        type:Object,
      },
      ShowLevel:{
        type:Boolean,
        default:false
      },
    },
    data() {
      return {
        totalDuration:'00:00:00',
        totalInCorrect:0,
        totalCorrect:0,
        userAnswers:[]
      };
    },
    computed: {
      exercises(){
        let result = [];
        if(this.ExcerciseData && this.ExcerciseData.length>0){
            result = this.ExcerciseData;
        }
        return result;
      },
      excerciseUserAnswerData(){
        let result = [];
        if(this.ExcerciseUserAnswerData && this.ExcerciseUserAnswerData.length>0){
            result = this.ExcerciseUserAnswerData;
        }
        return result;
      },
    },
    methods: {
      GetUserAnswer(question){
        if(!this.userAnswers || this.userAnswers.length==0) return;
        let id = question.id;
        let answer = this.userAnswers.find(x=>x.Id===id).Answer;
        return question.answers[answer];
      },
      GetRightAnswer(question){
        return question.answers[question.right];
      },
      IsSuccess(question) {
          if(!this.userAnswers || this.userAnswers.length==0) return;

          let id = question.id;
          let right = question.right;
          let answer = this.userAnswers.find(x=>x.Id===id).Answer;
          if (parseInt(answer,10) === parseInt(right,10))
            return true;
          else 
            return false;
      },
      
      QuestionControl(){
        this.totalCorrect = 0;
        this.totalInCorrect = 0;
        for (let i = 0; i < this.exercises.length; i++) {
          let data = this.exercises[i];
          let answer = this.userAnswers.find(x=>x.Id===data.id).Answer;  
          if(data.right===answer){
            this.totalCorrect++;
          }else{
            this.totalInCorrect++;
          }
        }
      },
      GetLevelTestPuan(value) {
          return '"'+GetLevelTextByCurrectPuan(value)+'"';
      },
    },
    watch: {
      ExcerciseUserAnswerData:function(value){
          if(value){
            this.userAnswers = JSON.parse(value.answerContent);
            this.QuestionControl();
            let d1 = new Date(value.finishDate);
            let d2 = new Date(value.beginDate);
            this.totalDuration = ConvertMiliSecoundToSecound((d1.getTime() - d2.getTime()));
          }
      }
    },
    
  };
  </script>
  
  <style>
  </style>
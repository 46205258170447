import { toast } from 'vue3-toastify';

export function ToastType(){
    return toast.TYPE;
}

export function ErrorToast(message){
    const options = {autoClose: 1500,theme:'auto',type:'error'};
    toast(message,options);
}

export function LoadingToast(){
    const options = {position:toast.POSITION.BOTTOM_RIGHT};
    const id = toast.loading('Lütfen bekleyin...',options);
    return id;
}

export function UpdateToast(id,messsage,type,loadingTime=1500){
    const options = {autoClose: loadingTime,theme:'auto',render:messsage,type:type,isLoading:false};
    toast.update(id,options);
}

export function RemoveToast(id){
    toast.remove(id);
}

export function CampaingToast(message){
    const options = {position:toast.POSITION.BOTTOM_RIGHT,dangerouslyHTMLString:true,transition:toast.TRANSITIONS.SLIDE,type:toast.TYPE.DEFAULT,theme:toast.THEME.LIGHT,autoClose:5000};
    toast(message,options);
}
<template>
    <div class="d-flex align-items-center py-4">
      <div class="form-forgot-password rounded-3 bg-white w-100 m-auto">
          <h3 class="text-center">Parolamı Unuttum</h3>
          <p class="text-center">Parolanı nasıl sıfırlayacağına ilişkin talimatları sana e-postayla göndereceğiz.</p>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="email" v-model="form.username" placeholder="name@example.com" autocomplete="email">
            <label for="email">E-posta</label>
          </div>
          <button class="btn btn-primary w-100 py-2" type="button" @click="submit()" :disabled="form.isLoading">Gönder</button>
          <div class="text-center mt-3">
            Zaten bir şifrem var.<router-link :to="{path:'/'}" class="btn  fw-bold">Giriş Yap.</router-link>
          </div>
      </div>
    </div>
    <div><VersionNumber /></div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import VersionNumber from "@/components/Shared/VersionNumber.vue";
  import { LoadingToast,ErrorToast,UpdateToast,ToastType } from "@/common/notification.js";
  import {IsEmailValid} from "@/common/validation-email";
  export default {
    name: "ForgotPasswordPage",
    components: {
      VersionNumber
    },
    data() {
      return {
        form: {
          username: "",
          isLoading:false
        },
      };
    },
    methods: {
      ...mapActions(["ForgotPassword"]),
      async submit() {
        if(!IsEmailValid(this.form.username)){
            ErrorToast('E-posta adresi geçersiz.');
            return;
        }

        let toastId = LoadingToast();
        this.form.isLoading=true;
        const request = {
          "UserName": this.form.username,
          "PanelType": 2
        };
        await this.ForgotPassword(request)
        .then((res)=>{
          this.form.isLoading=false;
          UpdateToast(toastId,res.description,ToastType().SUCCESS);
        })
        .catch(err=>{
          UpdateToast(toastId,err.message,ToastType().ERROR);
          this.form.isLoading=false;
        });
      },
    },
  };
  </script>
  
  <style scoped>
    .form-forgot-password {
      max-width: 330px;
      padding: 1rem;
    }
    
    .form-forgot-password .form-floating:focus-within {
      z-index: 2;
    }
  </style>
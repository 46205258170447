<template>
<div>
    <div v-if="isLoading" class="text-center">
        <div class="spinner-border">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else>
        <ul class="nav nav-tabs justify-content-end">
            <li class="nav-item active" v-if="IsContent(1)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(1)">Bölüm 1</a>
            </li>
            <li class="nav-item" v-if="IsContent(2)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(2)">Bölüm 2</a>
            </li>
            <li class="nav-item" v-if="IsContent(3)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(3)">Bölüm 3</a>
            </li>
            <li class="nav-item" v-if="IsContent(6)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(6)">Bölüm 4</a>
            </li>
            <li class="nav-item active" v-if="IsContent(10)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(10)">Diyalog 1</a>
            </li>
            <li class="nav-item" v-if="IsContent(11)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(11)">Diyalog 2</a>
            </li>
            <li class="nav-item" v-if="IsContent(12)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(12)">Diyalog 3</a>
            </li>
            <!-- <li class="nav-item" v-if="IsContent(13)">
                <a class="nav-link text-dark" href="javascript:;" @click="GetContent(13)">Okuma</a>
            </li> -->
        </ul>
        <div class="tab-content p-2">
            <div class="tab-pane fade show active" v-if="bookContent!=null">
                <a v-if="bookContent.bookUrl!=null" v-bind:href="bookContent.bookUrl" target="_blank"><img :src="bookContent.bookUrl" class="img-fluid"></a>
                <div class="mt-2" v-if="bookContent.contentHtml!=null" >
                    <div class="text-end mb-1"><small>[ <a href="javascript:;" @click="ChangeLanguage()" class="link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover" v-html="ChangeLanguageButtonText"></a> ]</small></div>
                    <div v-if="bookContent.contentTitle!=null" class="book-title mb-5">
                        <h3 >{{ bookContent.contentTitle }}</h3>       
                    </div>
                    <div v-if="bookContent.contentDialogs.length==0" v-html="bookContent.contentHtml"></div>
                    <div v-if="bookContent.contentDialogs.length>0">
                        <div class="dialogbox" v-for="(d,index) in bookContent.contentDialogs" :key="d">
                            <div class="body" :class="{'body-right' : (index%2)!=0,'body-left':(index%2)==0}">
                                <span class="tip-name" :class="{'tip-name-right' : (index%2)!=0,'tip-name-left':(index%2)==0}">
                                    <div v-html="d.name"></div>
                                    <a v-if="isReadyAudioButton && d.audio!=''" href=javascript:; @click="playAudio(d.audio,$event)" :disabled="isPlay"><i class="bi bi-volume-up-fill"></i></a>
                                </span>
                                <span class="tip" :class="{'tip-right' : (index%2)!=0,'tip-left':(index%2)==0}"></span>
                                <div class="message" :class="{'message-right' : (index%2)!=0}" v-html="d.content"></div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <audio ref="audio" src=""></audio>
    </div>
</div> 
</template>
<script>
 import { ErrorToast} from "@/common/notification.js";
  export default {
    name: "TabLearnSubject",
    props: {
      contents:{
        type:Array
      }
    },
    data() {
      return {
        contentType:0,
        contentLanguage:'en-EN',
        bookContent:null,
        isLoading:true,
        audioSrc:'',
        audioExists:false,
        isReadyAudioButton:false,
        selectAudioButton:null,
        isPlay:false
      }
    },
    computed: {
       ChangeLanguageButtonText(){
            if(this.contentLanguage=='en-EN'){
                return 'Türkçe Göster <i class="bi bi-eye"></i>';
            }else{
                return 'Türkçe Gizle <i class="bi bi-eye-slash"></i>';
            }
       }
    },
    methods: {
        IsContent(value){
            if(this.contents.length>0)
            {
                let find = this.contents.find(x=>x.contentType===value);
                if(find){
                    return true;
                }
            }
            return false;
        },
        GetContent(contentType){
            this.contentType = contentType;
            let contentHtml = '',contentTitle='',contentHtmlTr = '',contentTitleTr='',contentImageUrl='';
            let contentDialogs=[],contentDialogsTr=[];
            if(this.contents.length){
                let findBookContent = this.contents.filter(x=>x.contentType==contentType)[0];
                if(findBookContent){
                    contentTitle = findBookContent.contentTitle;
                    contentImageUrl = findBookContent.fileName==null ? null : process.env.VUE_APP_ROOT_API+'Book/BookImage?fileName=' + findBookContent.fileName;
                    contentHtml = findBookContent.contentHtml;
                    if(findBookContent.contentType==10 || findBookContent.contentType==11 || findBookContent.contentType==12){
                        if(findBookContent.dialogs.length>0){
                            for (let i = 0; i < findBookContent.dialogs.length; i++) {
                                contentDialogs.push({name:findBookContent.dialogs[i].name.replace(':',''),content:findBookContent.dialogs[i].content,audio:findBookContent.dialogs[i].audioFile});
                            }
                        }
                        let findBookContentTr = findBookContent.languages.filter(x=>x.languageCode=='tr-TR');
                        if(findBookContentTr && findBookContentTr.length>0){
                            contentTitleTr = findBookContentTr[0].contentTitle;
                            contentHtmlTr = findBookContentTr[0].contentHtml;
                            if(findBookContentTr[0].dialogs.length>0){
                                for (let i = 0; i < findBookContentTr[0].dialogs.length; i++) {
                                    contentDialogsTr.push({name:findBookContentTr[0].dialogs[i].name.replace(':',''),content:findBookContentTr[0].dialogs[i].content,audio:''});
                                }
                            }
                        }
                    }
                }
            }

            this.bookContent = {
                bookUrl: contentImageUrl,
                contentHtml : this.contentLanguage=='en-EN'?contentHtml:contentHtmlTr,
                contentTitle: this.contentLanguage=='en-EN'?contentTitle:contentTitleTr,
                contentDialogs: this.contentLanguage=='en-EN'?contentDialogs:contentDialogsTr,
            };

        },
        ChangeLanguage(){
            this.contentLanguage = this.contentLanguage == 'en-EN'?'tr-TR':'en-EN';
            this.GetContent(this.contentType)
        },

        async playAudio(value,e){
            if(this.isPlay)
                return;
            this.selectAudioButton = e;
            this.selectAudioButton.target.style.color='#ff9800';
            let url = process.env.VUE_APP_ROOT_API+"Source/dictionary/bookdialogaudio?fileName="+value;
            await this.checkAudioFile(url);
            if(this.audioExists){
                this.audioSrc = url;
            } else{
                ErrorToast('Ses dosyası oynatılamadı.');
            }
       },
       async checkAudioFile(audioFile) {
            await fetch(audioFile, { method: 'GET' })
            .then(response => {
                this.audioExists = response.ok;
                })
            .catch(() => {
                this.audioExists = false;
              });
        },
        onAudioEnded() {
            this.selectAudioButton.target.style.color='#333';
            this.isPlay=false;
        }
    },
    mounted: function() {
        let mainThis = this;
        setTimeout(() => {
            mainThis.$refs.audio.addEventListener('ended', mainThis.onAudioEnded);
            this.isReadyAudioButton=true;
        }, 1500);
        
        
    },
    beforeUnmount() {
        this.$refs.audio.removeEventListener('ended', this.onAudioEnded);
    },
    watch:{
        contents(value){
            if(value){
                this.isLoading=false;
                if(this.IsContent(1)){
                this.GetContent(1);
                }else if(this.IsContent(10)){
                this.GetContent(10);
                }
            }
        },
        audioSrc(value){
            if (value) {
                this.$refs.audio.src=value;
                this.$refs.audio.play(); 
                this.isPlay=true;
            }
        },
    }
  };
  </script>
    
<style scoped>   
    .tip {
    width: 0px;
    height: 0px;
    position: absolute;
    background: transparent;
    }

    /* .tip-up {
    top: -25px;
    left: 10px;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    }

    .tip-down {
    bottom: -25px;
    left: 10px;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;  
    } */

    .tip-left {
    top: 10px;
    left: -22px;
    border: 10px solid #c57c19;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;  
    }

    .tip-right {
    top: 10px;
    right: -22px;
    border: 10px solid #3e9338;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;  
    }

    .tip-name{
        width: 100px;
        height: 0px;
        position: absolute;
        font-weight: 500;
    }

    .tip-name-left{
        left: -100px;
    }

    .tip-name-right{
        right: -100px;
        text-align: right;
    }

    .tip-name a{font-size: x-large; color: #333;}
    .dialogbox .body {
    position: relative;
    max-width: 80%;
    height: auto;
    margin: 20px 10px;
    padding: 10px;
    border-radius: 10px;
    }

    .dialogbox .body-left{
        left:100px;
        background-color: #f3c587;
        border: 2px solid #c57c19;
        
    }

    .dialogbox .body-right{
        right:0;
        background-color: #89d184;
        border: 2px solid #3e9338;
    }

    .body .message {
        min-height: 30px;
        border-radius: 3px;
        font-size: 16px;
        line-height: 1.5;
        color:#333;
        
    }
    .body .message-right {
        text-align: right;        
    }

    .book-title{
        background-color: rgb(28 49 70);
        color:#FFF;
        text-align: center;
        padding: 15px 0 15px;
        -webkit-border-top-left-radius: 100px;
        -webkit-border-bottom-right-radius: 100px;
        -moz-border-radius-topleft: 100px;
        -moz-border-radius-bottomright: 100px;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
    }
    @media (max-width: 575px) {

        .tip {
            display:none;
            }

            .tip-name{
                width: 100%;
                position:inherit;
                left: auto;
                right: auto;
                display: inline-table;
            }

            .tip-name-left{
                text-align: left;
            }

            .tip-name-right{
                text-align: right;
            }
            .dialogbox .body {
                max-width: 100%;
                left:0px;
                right:0px;
            }
            

            .body .message {
                font-size: 14px;
            }
            .body .message-right {
                text-align: left;
            }

            .book-title{
                -webkit-border-top-left-radius: 50px;
                -webkit-border-bottom-right-radius: 50px;
                -moz-border-radius-topleft: 50px;
                -moz-border-radius-bottomright: 50px;
                border-top-left-radius: 50px;
                border-bottom-right-radius: 50px;
            }

    }
</style>
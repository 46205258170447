<template>
  <NavBar />
  <div class="container py-4">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card card-cover rounded-4 border-0">
          <div class="card-body">
            <div class="display-6 fs-4 mb-3">Rastegele bir soru oluştur : </div>
            <div class="row">
              <div class="col-lg-7 border-end">
                <div class="display-6 lh-1 fw-bold pt-5 pb-5">{{ question }}</div>
              </div>
              <div class="col-lg-5">
                <div class="fw-bold">Farklı hale getirin</div>
                <div class="mt-2 mb-2">Soruyu cevaplarken aşağıdaki kelimeleri kullanmaya çalışın.</div>
                <ul>
                  <li>{{wordOne}}</li>
                  <li>{{wordTwo}}</li>
                  <li>{{wordThree}}</li>
                  <li>{{wordFour}}</li>
                </ul>
              </div>
            </div>
            <button class="btn btn-lg btn-primary" @click="GetQuestion()">Başka Soru Deneyin</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterInPage />
</template>

<script>
import { mapActions } from "vuex";
import { LoadingToast,UpdateToast,ToastType } from "@/common/notification.js";
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
export default {
  name: 'RandomQuestion',
  data() {
    return {
      question:'',
      wordOne:'',
      wordTwo:'',
      wordThree:'',
      wordFour:'',
    };
  },
  components: {
    NavBar,
    FooterInPage,
  },
  computed:{
  },
  methods: {
    ...mapActions(["GetRandomQuestion"]),
    async GetQuestion(){
      let toastId = LoadingToast();
      await this.GetRandomQuestion()
      .then((res)=>{
        this.question = res.question;
        this.wordOne = res.answerOne;
        this.wordTwo = res.answerTwo;
        this.wordThree = res.answerThree;
        this.wordFour = res.answerFour;
        UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
      })
      .catch((err)=>{
        UpdateToast(toastId,err.message,ToastType().ERROR);
      });
    },
  },
  mounted: function() {
    this.GetQuestion();
  }
}
</script>
<style>
</style>
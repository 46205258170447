function GetLevelTextByCurrectPuan(value) {
    let trueAnswerCount = parseInt(value, 10);
    if (trueAnswerCount == 0)
        return '';

    if (trueAnswerCount >= 1 && trueAnswerCount <= 15) {
        return 'A1';
    } else if (trueAnswerCount >= 16 && trueAnswerCount <= 26) {
        return 'A2';
    } else if (trueAnswerCount >= 27 && trueAnswerCount <= 42) {
        return 'B1';
    } else if (trueAnswerCount >= 43 && trueAnswerCount <= 50) {
        return 'B2';
    } else if (trueAnswerCount >= 51 && trueAnswerCount <= 55) {
        return 'C1';
    } else if (trueAnswerCount >= 56 && trueAnswerCount <= 60) {
        return 'C2';
    } else {
        return '';
    }
}

function GetExercisesCategory() {
    let result = [
      {id:1,label:'Okuma ve Dinleme',image:'reading-listening.svg'},
      {id:21,label:'Spor',image:'spor.svg'},
      {id:22,label:'İş',image:'working.svg'},
      {id:23,label:'Sağlık',image:'health.svg'},
      {id:25,label:'Flört',image:'love.svg'},
      {id:26,label:'Eğlence',image:'fun.svg'},
      {id:27,label:'Alışveriş',image:'purchase.svg'},
      {id:28,label:'Okul Hayatı',image:'school.svg'},
      {id:29,label:'Seyahat',image:'trip.svg'},
      {id:31,label:'Yemeğe Çıkmak',image:'lunch-time.svg'},
      {id:32,label:'Yemek',image:'chef.svg'},
      {id:50,label:'İş Görüşmesi Soruları',image:'interview.svg'},
      {id:24,label:'Konut',image:'house.svg'},
      {id:30,label:'Ulaşım',image:'bus.svg'},
      {id:20,label:'Günlük Hayat',image:'before-dawn.svg'},
    ]

    return result;
}

function GetRandomExercisesCategory() {
    let data = GetExercisesCategory();
    let result=[];
    let count = 0;
    do {
        let exercise = data[Math.floor(Math.random()*data.length)];
        let find = result.find(x=>x.id===exercise.id);
        if(find!=null)
        {
            continue;
        }
        result.push(exercise);
        count++;
    } while (count<6);

    return result;
}

export { GetLevelTextByCurrectPuan,GetExercisesCategory,GetRandomExercisesCategory }
import { service } from "@/common/axios-service";
const state = {
}

const getters={
}

const actions ={
  async GetExercises(context,form) {
    return new Promise((resolve,reject)=>{
      service.post("Student/GetExercises?category="+form,{})
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
}

const mutations ={
}

export default{
    state,
    getters,
    actions,
    mutations
};
import axios from "axios";
import store from "@/store";
import router from "@/router";

const _baseUrl = process.env.VUE_APP_ROOT_API;
const service = axios.create({
    baseURL:_baseUrl,
    timeout:60000,
    withCredentials:false
});

service.interceptors.request.use(function(config){
    config.headers['Accept']='application/json';
    config.headers['Content-Type'] = 'application/json';
    if(store.getters["isAuthenticated"]){
        let token = store.getters["StateUser"].token;
        config.headers['Authorization'] = 'Bearer '+token;
    }
    return config;
}, undefined);

service.interceptors.response.use(undefined, async function(error) {
    if (error) {
       const originalRequest = error.config;
       if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry=true;
        let result = await GetRefreshToken(originalRequest);
        if(result.status){
            service.defaults.headers.common['x-access-token']='Bearer '+ result.newToken;
            return service(originalRequest);
        }else{
            store.dispatch("LogOut");
            return router.push("/login");
        }
       }
       if (error.response.status===400){
            return Promise.reject(error.response.data);
       }else{
            console.log('Error.')
       }
   }
});

async function GetRefreshToken(){
    let result = {'status':false,'newToken':null};
    try {
        let refreshToken = store.getters["StateUser"].refreshToken;
        await axios.create({
            baseURL : _baseUrl,
            timeout:60000
        }).post('Login/RefreshToken', {PanelType:2,RefreshToken:refreshToken})
        .then(res=>{
            store.commit("setUser", res.data);
            result.newToken = store.getters["StateUser"].token;
            result.status = true;
        })
        .catch(()=>{
            result.status = false;
        }); 

    } catch {
        result.status = false;
    }
    return result;
}

export {service};
<template>
  <NavBar />
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-11">
        <div class="card mb-2">
            <div class="row g-0 mb-2 border-bottom">
              <div class="col-md-2">
                <img :src="require('@/assets/images/'+book.image)" class="img-fluid rounded-start"
                  style="height: 90px;" alt="...">
              </div>
              <div class="col-md-10">
                <div class="card-body p-2">
                  <div class="row">
                    <div class="col-8">
                      <h5 class="fw-bold">{{book.title}}</h5>
                      <small>{{book.description}}</small>
                    </div>
                    <div class="col-4">
                      <span>%25</span><span class="text-muted float-end">{{book.subjects.length}} Ünite</span>
                      <div class="progress mt-1 mb-2" style="height: 6px;">
                        <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25"
                          aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <span class="badge rounded-pill bg-primary me-1 float-end">{{book.level}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0">
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="s in book.subjects" :key="s">
                  <span class="float-start">Unit-{{ s.sequence }} : <span class="text-capitalize">{{s.subject}}</span></span>
                  <router-link :to="{path:'/courses/'+book.id+'/'+s.id}"><i class="bi bi-play-circle float-end ms-1" style="font-size: 1.5rem;"></i></router-link>
                  <!-- <i class="bi bi-check2-circle float-end text-success" style="font-size: 1.5rem;"></i> -->
                </li>
              </ul>
            </div>
          </div>
      </div>
    </div>
  </div>
  <FooterInPage />
</template>

<script>
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
import bookList from '@/mockData/bookdata'
export default {
  name: 'CourseUnitsPage',
  components: {
    NavBar,
    FooterInPage,
  },
  data() {
    return {
      
    };
  },
  computed:{
      book(){
        let id = this.$route.params.unit;
        return bookList.bookList.find(x=>x.id==id);
      }
    },
  methods: {
   
  },

}
</script>
<style></style>
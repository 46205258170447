<template>
  <NavBar />
  <div class="container py-4">
    <div class="row">

      <div class="col-lg-4">
        <div class="list-group">
          <a href="javascript:;" class="list-group-item list-group-item-action" :class="{'active':selectedCategory==1}" @click="GetCategory(1)"><i class="bi bi-caret-right-fill"></i> Level Test</a>
          <a href="javascript:;" class="list-group-item list-group-item-action" @click="GrammarToggle()">
            <i class="bi bi-caret-down-fill" v-if="grammarVisible"></i>
            <i class="bi bi-caret-right-fill" v-else></i> 
            Grammar</a>
          <ul class="list-group list-group-flush mt-2" v-show="grammarVisible">
            <li class="list-group-item" v-for="d in grammarList" :key="d" :class="{'active':selectedCategory==d.id}" @click="GetCategory(d.id)" style="cursor: pointer;">{{d.label}}</li>
          </ul>
          <a href="javascript:;" class="list-group-item list-group-item-action" @click="VocabularyToggle()">
            <i class="bi bi-caret-down-fill" v-if="vocabularyVisible"></i>
            <i class="bi bi-caret-right-fill" v-else></i> 
            Vocabulary</a>
          <ul class="list-group list-group-flush mt-2" v-show="vocabularyVisible">
            <li class="list-group-item" v-for="d in vocabularyList" :key="d" :class="{'active':selectedCategory==d.id}" @click="GetCategory(d.id)" style="cursor: pointer;">{{d.label}}</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card border-0">
          <div class="card-body">
            <h3>{{selectedCategoryTitle}}</h3>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Test Başlığı</th>
                  <th style="width:170px"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="e in tests" :key="e">
                  <td>{{ e.title }} <span class="fw-light fst-italic"><small>({{ e.questions.length }} adet soru)</small></span></td>
                  <td class="text-end">
                    <a href="javascript:;" class="btn btn-primary btn-sm me-1" @click="OpenCloseExerciseResultModal(true,e.title,e.historyAnswers)">Sonuçlar</a>
                    <a href="javascript:;" class="btn btn-primary btn-sm" @click="OpenCloseTestModal(true,e.title,e.questions,e.id)">Başla<i class="bi bi-arrow-right-short"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterInPage />
  <TestModal 
    :Title="this.selectedTest.title" 
    :ExcerciseData="this.selectedTest.questions" 
    :ExcerciseId="this.selectedTest.id"
    :Visible="testModalVisible"
    :IsLevelTest=IsLevelTest
    @CloseModal="OpenCloseTestModal($event,'',[],'')" 
    />

    <ExerciseResultModal 
      :Title="this.selectedTest.title" 
      :ExcerciseResultData="this.selectedTest.result"
      :Visible="exerciseResultModalVisible" 
      :IsLevelTest=IsLevelTest
      @CloseModal="OpenCloseExerciseResultModal($event,'',[])" 
      
    />
</template>

<script>
import { mapActions } from "vuex";
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
import { LoadingToast,UpdateToast,ToastType } from "@/common/notification.js";
import TestModal from '@/components/Modals/TestModal.vue'
import ExerciseResultModal from '@/components/Modals/ExerciseResultWithChartModal.vue'
export default {
  name: 'TestPage',
  data() {
    return {
      selectedCategory:0,
      selectedCategoryTitle:'',
      tests:[],//[{title:'Level Test - 1',questions:[{number:1,question:'I am a ____________.',answer:0,options:['book','notebook','doctor','engineer']}],result:[{date:'2023-05-21 15:40:11',currect:3,incurrect:13},{date:'2023-05-22 15:40:11',currect:25,incurrect:1}]},{title:'Level Test - 2',questions:[{number:1,question:'Where are your ____________.',answer:0,options:['name','from','doctor','engineer']}],result:[]},{title:'Level Test - 3',questions:[{number:1,question:'What is your ____________.',answer:0,options:['name','notebook','doctor','engineer']}],result:[]}],
      grammarList:[],
      grammarVisible:false,
      vocabularyList:[],
      vocabularyVisible:false,
      testModalVisible:false,
      exerciseResultModalVisible:false,
      selectedTest:{
        id:'',
        title:'',
        questions:[],
        result:[]
      }
    };
  },
  components: {
    NavBar,
    FooterInPage,
    TestModal,
    ExerciseResultModal
  },
  computed:{
    IsLevelTest(){
      return this.selectedCategory===1;
    }
  },
  methods: {
    ...mapActions(["GetTests"]),
    async GetCategory(value){
      this.selectedCategory=value;
      if(value===1){
        this.selectedCategoryTitle = "Level Test";
      }else if(value>=11 && value<=14){
        let category = this.grammarList.find(x=>x.id==value);
        this.selectedCategoryTitle = 'Grammer / '+ category.label;
      }else if(value>=16 && value<=19){
        let category = this.vocabularyList.find(x=>x.id==value);
        this.selectedCategoryTitle = 'Vocabulary / '+ category.label;
      }

      let toastId = LoadingToast();
      await this.GetTests(value)
      .then((res)=>{
        this.tests = res;
        UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
      })
      .catch((err)=>{
        UpdateToast(toastId,err.message,ToastType().ERROR);
      });
    },
    GrammarToggle(){
      if(this.grammarVisible){
        this.grammarVisible=false;
      }else{
        this.grammarVisible=true;
      }
    },
    VocabularyToggle(){
      if(this.vocabularyVisible){
        this.vocabularyVisible=false;
      }else{
        this.vocabularyVisible=true;
      }
    },
    OpenCloseTestModal(visible,title,questions,id){
     this.selectedTest.id=id;
     this.selectedTest.title=title;
     this.selectedTest.questions=questions;
     this.testModalVisible=visible;
    },
    OpenCloseExerciseResultModal(visible,title,result){
     this.selectedTest.title=title;
     this.selectedTest.result=result;
     this.exerciseResultModalVisible=visible;
    },
  },
  mounted: function() {
    this.grammarList =[
      {id:11,label:'Beginner'},
      {id:12,label:'Elementary'},
      {id:13,label:'Intermediate'},
      {id:14,label:'Advanced'},
    ];
    this.vocabularyList =[
      {id:16,label:'Beginner'},
      {id:17,label:'Elementary'},
      {id:18,label:'Intermediate'},
      {id:19,label:'Advanced'},
    ];
    this.GetCategory(1);  
  }
}
</script>
<style>
</style>
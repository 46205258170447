<template>
  <div v-for="n in notificationList" :key="n" class="clearfix">
    <div v-html="n.content"></div>
  </div>
</template>
  <script>
  import { mapActions } from "vuex";  
  import {GetCookie, SetCookie} from "@/common/cookie-helper";
  export default {
    name: "NotificationForTopPage",
    data(){
      return{
        notificationList:[],
      }
    },
    components: {
    },
    computed: {
    },
    methods: {
      ...mapActions(["GetNotification"]),
      RemindMeLater(){
        let selected = this.notification;
        if(selected.id!=''){
          SetCookie(selected.cookieKey,'true',selected.remindMeLaterDuration);
        }
        this.NextNotification();
      },
      CheckNotificationForCookie(notifications){
        let result = [];
        for (let index = 0; index < notifications.length; index++) {
          const n = notifications[index];
          var cookie = GetCookie(n.cookieKey);
          if(cookie==''){
            result.push(n);
          }
        }
        return result;
      },
      async Init(){
        await this.GetNotification({viewZone:1})
        .then((res)=>{
          this.notificationList = this.CheckNotificationForCookie(res);
        })
        .catch(()=>{});
      }
    },
    mounted: function() {
      this.Init();
    }
  };
  </script>
  
  <style>
    
  </style>
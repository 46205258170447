<template>
  <NavBar />
  <div class="container py-4">
    
    <div class="row" v-if="!showDetail">
      <div class="col-lg-3 mb-3" v-for="d in dialogAndListening" :key="d">
        <div class="card border-0">
          <img v-bind:src="require('../assets/images/excercises/'+d.image)" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">{{d.label}}</h5>
            <p class="card-text text-light-emphasis">Makaleleri okuyun ve tartışın!</p>
            <a href="javascript:;" class="btn btn-sm btn-primary" @click="GetCategory(d.id)">Daha fazla bilgi <i class="bi bi-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="showDetail">
      <div class="col-lg-12">
        <div class="card rounded-3 border-0">
          <div class="card-body">
            <div class="d-flex flex-sm-row flex-column">
              <button class="btn btn-sm btn-primary me-lg-3 mb-2 mb-lg-0" @click="()=>{showDetail=false}"><i class="bi bi-arrow-left"></i> Egzersizler</button>
              <h3 class="text-center text-lg-start">{{selectedCategoryTitle}}</h3>
            </div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th style="width:70px" scope="col">Ünite</th>
                  <th scope="col">Konu</th>
                  <th style="width:100px"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="e in exercises" :key="e">
                  <td scope="row">{{ e.sequence }}.</td>
                  <td class="text-uppercase">{{ e.title }}</td>
                  <td class="text-end">
                    <a href="javascript:;" class="btn btn-sm btn-primary" @click="OpenCloseExcerciseModal(true,e.title,e.sequence,e.content,e.isImage,e.isSound,e.isTranslate)">Başla<i class="bi bi-arrow-right-short"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
  <FooterInPage />
  <ExcerciseModal 
    :Title="this.selectedExercise.title" 
    :Category="this.selectedCategory"
    :Sequence="this.selectedExercise.sequence"
    :Content="this.selectedExercise.content"
    :IsImage="this.selectedExercise.isImage"
    :IsAudio="this.selectedExercise.isAudio"
    :IsTranslate="this.selectedExercise.isTranslate"
    :Visible="excerciseModalVisible" 
    @CloseModal="OpenCloseExcerciseModal($event,'',0,'',false,false,false)" />
</template>

<script>
import { mapActions } from "vuex";
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
import ExcerciseModal from '@/components/Modals/ExcerciseModal.vue'
import {GetExercisesCategory} from '@/common/test-helper';
import { LoadingToast,UpdateToast,ToastType } from "@/common/notification.js";
export default {
  name: 'ExercisePage',
  data() {
    return {
      showDetail:false,
      selectedCategory:0,
      selectedCategoryTitle:'',
      selectedExercise:{
        title:'',
        sequence:0,
        content:'',
        isImage:false,
        isAudio:false,
        isTranslate:false
      },
      dialogAndListeningVisible:false,
      excerciseModalVisible:false,
      dialogAndListening:[],
      exercises :[]
    };
  },
  components: {
    NavBar,
    FooterInPage,
    ExcerciseModal
  },
  computed:{
  },
  methods: {
    ...mapActions(["GetExercises"]),
    async GetCategory(value){
      this.showDetail=true;
      this.selectedCategory=value;
      let category = this.dialogAndListening.find(x=>x.id==value);
      if(category){
        this.selectedCategoryTitle = category.label;
        //switch (value) {
        //  case 1:
        //    this.selectedCategoryTitle = category.label;
        //    break;
        //  case 50:
        //    this.selectedCategoryTitle = category.label;
        //    break;
        //  default:
        //    this.selectedCategoryTitle = 'Diyalog ve Dinleme / '+ category.label;
        //    break;
        //}

        let toastId = LoadingToast();
        await this.GetExercises(value)
        .then((res)=>{
          this.exercises = res;
          UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
        })
        .catch((err)=>{
          UpdateToast(toastId,err.message,ToastType().ERROR);
        });
      }
    },
    // DialogAndListeningToggle(){
    //   if(this.dialogAndListeningVisible){
    //     this.dialogAndListeningVisible=false;
    //   }else{
    //     this.dialogAndListeningVisible=true;
    //   }
    // },
    OpenCloseExcerciseModal(visible,title,sequence,content,isImage,isAudio,isTranslate){
      this.selectedExercise.title=title;
      this.selectedExercise.sequence=sequence;
      this.selectedExercise.content=content;
      this.selectedExercise.isImage=isImage;
      this.selectedExercise.isAudio=isAudio;
      this.selectedExercise.isTranslate=isTranslate;
      this.excerciseModalVisible=visible;
    },
  },
  mounted: function() {
    this.dialogAndListening = GetExercisesCategory();
    if(this.$route.query.q){
       this.GetCategory(parseInt(this.$route.query.q,10));
    }
  }
}
</script>
<style>
</style>
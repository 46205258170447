<template>
 <div class="modal fade" tabindex="-1" v-bind:id="ModalName" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5 text-uppercase">{{ Title }}</h1>
          <button v-if="ShowCloseButton" type="button" class="btn-close" @click="CloseModal()"></button>
        </div>
        <div class="modal-body" v-html="Content"></div>
        <div v-if="ShowRefuseButton || ShowAcceptButton" class="modal-footer">
          <button v-if="ShowRefuseButton" type="button" class="btn btn-sm btn-secondary" @click="Refuse()" v-html="RefuseButtonText"></button>
          <button v-if="ShowAcceptButton" type="button" class="btn btn-sm btn-primary" @click="Accept()" v-html="AcceptButtonText"></button>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import bootstrapBundle from 'bootstrap/dist/js/bootstrap.bundle';
  export default {
    name: "CustomDialogModal",
    props: {
      ModalName:{
        type:String,
        default:'ModalCustomDialog'
      },
      Visible:{
        type:Boolean,
        default:false
      },
      Title:{
        type:String,
        default:''
      },
      Content:{
        type:String,
        default:''
      },
      ShowCloseButton:{
        type:Boolean,
        default:true
      },
      ShowAcceptButton:{
        type:Boolean,
        default:true
      },
      ShowRefuseButton:{
        type:Boolean,
        default:true
      },
      AcceptButtonText:{
        type:String,
        default:'Evet'
      },
      RefuseButtonText:{
        type:String,
        default:'Hayır'
      }
    },
    data() {
      return {
        
      };
    },
    computed: {
      
    },
    methods: {
      CloseModal(){
        this.modalElement.hide();
        this.$emit("CloseModal", false);
      },
      Accept(){
        this.$emit("CustomDialogAnswer",true);
        this.CloseModal();
      },
      Refuse(){
        this.$emit("CustomDialogAnswer",false);
        this.CloseModal();
      }
    },
    watch: {
      Visible(value){
        if(value){
          this.modalElement.show();
        }
      }
    },
    mounted: function() {
      let elementName = this.ModalName;
      this.modalElement = new bootstrapBundle.Modal(document.getElementById(elementName),{backdrop: 'static',keyboard: false});
    }
  };
  </script>
  
  <style>
  </style>
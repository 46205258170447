<template>
  <NavBar />
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-11">
        <div class="card border-0 rounded-3">
          <div class="card-body">

            <div class="alert alert-warning" v-if="isPostponed"> {{postponeDateView}} tarihli ders ertelenmiştir. İşlemi geri almak için danışmanınızı arayınız.</div>
            <div class="row">
              <div class="col-lg-4">
                <ul class="nav flex-column">
                  <li class="nav-item p-2">
                    <div class="clearfix">
                      <div class="float-start text-capitalize"><strong>{{ bookSubject }}</strong></div>
                      <div class="float-end pe-2">
                        <!-- <a href="javascript:;" class="text-dark me-2"><i class="bi bi-heart-fill"></i></a> -->
                        <span class="badge rounded-pill text-bg-warning" v-if="bookLevel!=null">{{ bookLevel }}</span>
                        <!-- <a href="javascript:;" class="text-dark"><i class="bi bi-share-fill"></i></a> -->
                      </div>
                    </div>
                    <!-- <div class="text-light-emphasis"><small>Bir yılda bulunan ayları öğrenceksiniz.</small></div> -->
                  </li>
                  <li><small>Ders Öncesi</small></li>
                  <li class="nav-item border-top">
                    <a  href="javascript:;" class="nav-link text-dark" :class="{'active':tabShow.tabSubjectVisible}" @click="OpenTab('TabSubject')"><i class="bi bi-book me-2"></i> Sayfa</a>
                  </li>
                  <li class="nav-item border-top" v-if="reading">
                    <a class="nav-link text-dark" href="javascript:;" :class="{'active':tabShow.tabReadingVisible}" @click="OpenTab('TabReading')"><i class="bi bi-bookmark me-2"></i> Okuma</a>
                  </li>
                  <li class="nav-item border-top" v-if="words.length>0">
                    <a class="nav-link text-dark" href="javascript:;" :class="{'active':tabShow.tabWordVisible}" @click="OpenTab('TabWord')"><i class="bi bi-x-diamond me-2"></i> Kelimeler</a>
                  </li>
                  <li class="nav-item border-top" v-if="audioDialogs.length>0">
                    <a class="nav-link text-dark" href="javascript:;" :class="{'active':tabShow.tabAudioVisible}" @click="OpenTab('TabAudio')"><i class="bi bi-headphones me-2"></i> Dinleme</a>
                  </li>
                  <li class="nav-item border-top" v-if="videoLesson!=null">
                    <a class="nav-link text-dark" href="javascript:;" :class="{'active':tabShow.tabVideoVisible}" @click="OpenTab('TabVideo')"><i class="bi bi-play-btn me-2"></i> Video</a>
                  </li>
                  <li class="nav-item border-top" v-if="animationVideo!=null">
                    <a class="nav-link text-dark" href="javascript:;" :class="{'active':tabShow.tabAnimationVideoVisible}" @click="OpenTab('TabAnimationVideo')"><i class="bi bi-play-btn me-2"></i> Animasyon</a>
                  </li>
                  <li><small>Ders Sonrası</small></li>
                  <li class="nav-item border-top" v-if="bookTest!=null && bookTest.length>0" >
                    <a class="nav-link text-dark" href="javascript:;" :class="{'active':tabShow.tabTestVisible}" @click="OpenTab('TabTest')"><i class="bi bi-card-checklist me-2"></i> Test</a>
                  </li>
                  <li class="nav-item border-top">
                    <a class="nav-link text-dark" href="javascript:;" :class="{'active':tabShow.tabTacherResultVisible}" @click="OpenTab('TabTeacherResult')"><i class="bi bi-person-video3 me-2"></i> Öğretmen Değerlendirmesi</a>
                  </li>

                  <li class="nav-item border-top">
                    <div class="my-2">
                      <button type="button" class="btn btn-sm btn-primary me-2" @click="OpenClosePostponeDialogModal(true)" :disabled="isPostponeButtonDisable"><i class="bi bi-arrow-clockwise"></i> Ertele</button>
                      <button type="button" class="btn btn-sm btn-secondary me-2" @click="NoteOpenClose()"><i class="bi bi-pencil-square"></i> Notlarım {{ noteVisible?'Kapat':'' }}</button>
                      <!-- <button type="button" class="btn btn-sm btn-outline-secondary me-2"><i class="bi bi-calendar2-plus"></i> Yardım Al</button> -->
                      <!-- <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-toggle="tab" data-bs-target="#feedback"><i class="bi bi-exclamation-triangle"></i> Sorun Bildir</button> -->
                    </div>
                    <!-- <a class="nav-link text-dark" href="#" id="feedback-tab" data-bs-toggle="tab" data-bs-target="#feedback"><i class="bi bi-exclamation-triangle me-2"></i> Sorun Bildir</a> -->
                  </li>
                  <li class="pt-3 text-center" v-show="tabShow.tabAudioVisible">
                    <div v-if="audioDialogs.length>0">
                      <div v-for="(a,i) in audioDialogs" :key="i">
                        <small class="text-body-tertiary" v-html="(a.title!=null)?a.title:'Kitap içindeki diyalogları dinleyebilirsiniz.'"></small>
                        <audio v-bind:ref="'AudioPlayer'" v-bind:src="a.url" controls controlsList="nodownload noplaybackrate" style="width:100%;" class="mt-1"></audio>
                      </div>
                      <!-- <div>
                        <small class="text-body-tertiary" v-html="(audioDialogs[0].title!=null)?audioDialogs[0].title:'Kitap içindeki diyalogları dinleyebilirsiniz.'"></small>
                        <audio v-bind:ref="'AudioPlayer0'" v-bind:src="audioDialogs[0].url" controls controlsList="nodownload noplaybackrate" style="width:100%;" class="mt-1"></audio>
                      </div> -->
                    </div>
                    <div v-else class="text-center">
                      Ses dosyası hazırlanıyor.
                    </div>
                    <!-- <div v-if="audioDialog!=null">
                      <small class="text-body-tertiary">Kitap içindeki diyalogları dinleyebilirsiniz.</small>
                      <audio ref="AudioPlayer" v-bind:src="audioDialog" controls controlsList="nodownload noplaybackrate" style="width:100%;" class="mt-1"></audio>
                    </div>
                    <div v-else class="text-center">
                      Ses dosyası hazırlanıyor.
                    </div> -->
                  </li>
                  <li class="pt-3" v-show="tabShow.tabVideoVisible">
                    <div v-if="videoLesson!=null">
                      <video ref="VideoPlayer" playsInline controls controlsList="nodownload noplaybackrate" style="width: 100%;height: 100%;object-fit:fill;" >
                        <source v-bind:src="videoLesson" type="video/mp4">
                      </video>
                    </div>
                    <div v-else class="text-center">
                      Video hazırlanıyor.
                    </div>
                  </li>
                  <li class="pt-3" v-show="tabShow.tabAnimationVideoVisible">
                    <div v-if="animationVideo!=null">
                      <video ref="AnimationVideoPlayer" playsInline controls controlsList="nodownload noplaybackrate" style="width: 100%;height: 100%;object-fit:fill;" >
                        <source v-bind:src="animationVideo" type="video/mp4">
                      </video>
                    </div>
                    <div v-else class="text-center">
                      Video hazırlanıyor.
                    </div>
                  </li>
                  <li class="pt-3" v-show="noteVisible">
                    <TipTapEditor :Description="scheduleLessonNote" @SaveLessonNote="SaveNoteLesson($event)"/>
                  </li>
                </ul>
              </div>
              <div class="col-lg-8">
                <div class="tab-content p-2" style="min-height: 400px;">
                  <div class="tab-pane fade" :class="{'show active':tabShow.tabSubjectVisible}">
                    <TabLearnSubject :contents="books"/>
                  </div>
                  <div class="tab-pane fade" :class="{'show active':tabShow.tabReadingVisible}">
                    <TabLearnReading :contents="books"/>
                  </div>
                  <div class="tab-pane fade" :class="{'show active':tabShow.tabWordVisible}">
                    <TabLearnWord :words="words"/>
                  </div>
                  <div class="tab-pane fade" :class="{'show active':tabShow.tabTeacherResultVisible}">
                    <TabResultSubject :BookResult="bookResult" />
                  </div>
                  <div class="tab-pane fade" :class="{'show active':tabShow.tabTestVisible}">
                    <TabTestSubject :BookTests="bookTest" :BookId="bookId"/>
                  </div>
                  <!-- <div class="tab-pane fade" id="feedback" role="tabpanel" >
                      <TabFeedbackSubject/>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <SaveNoteDialog 
  :ModalName="'SaveNote'"
  :ShowCloseButton="false"
  :Title="'Uyarı'"
  :Content="'Aldığınız notu kaydetmek istiyor musunuz?'" 
  :Visible="saveNoteDialogModalVisible" 
  @CloseModal="OpenCloseSaveNoteDialogModal($event)"
  @CustomDialogAnswer="SaveNoteDialogAnswer($event)"
  />
  <PostponeDialog 
  :ShowCloseButton="false"
  :Title="postponeDialogTitle"
  :Content="postponeDialogContent" 
  :Visible="postponeDialogModalVisible" 
  @CloseModal="OpenClosePostponeDialogModal($event)"
  @CustomDialogAnswer="PostponeDialogAnswer($event)"
  />
  <FooterInPage />
</template>

<script>
import {mapActions } from "vuex";
import {DateFormat} from '@/common/datetime-helper'
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
import TipTapEditor from "@/components/TextEditors/Tiptap.vue"

import TabLearnSubject from "@/components/Course/Detail/tab-learn-subject.vue";
import TabLearnReading from "@/components/Course/Detail/tab-learn-reading.vue";
import TabLearnWord from "@/components/Course/Detail/tab-learn-word.vue";
import TabTestSubject from "@/components/Course/Detail/tab-test-subject.vue";
import TabResultSubject from "@/components/Course/Detail/tab-result-subject.vue";
//import TabFeedbackSubject from "@/components/Course/Detail/tab-feedback-subject.vue";
import PostponeDialog from "@/components/Modals/CustomDialogModal.vue";
import SaveNoteDialog from "@/components/Modals/CustomDialogModal.vue";

import {LoadingToast,UpdateToast,ToastType} from "@/common/notification.js";
import {LevelText} from "@/common/enum-helper";
export default {
  name: 'CoursePage',
  components: {
    NavBar,
    FooterInPage,
    TabLearnSubject,
    TabLearnWord,
    TabTestSubject,
    TabResultSubject,
    TabLearnReading,
    //TabFeedbackSubject
    PostponeDialog,
    SaveNoteDialog,
    TipTapEditor,
  },
  data() {
    return {
      detail : null,
      time: 0,
      noteVisible:false,
      saveNoteDialogModalVisible:false,
      lessonNote:'',
      postponeDialogModalVisible:false,
      postponeDialogTitle:'Uyarı',
      postponeDialogConstContent:'<p>#LESSONDATE# tarihli dersinizi ertelemek istiyor musunuz?</p><strong>Not:</strong> Ders erteleme işlemini, ders saatinizden <strong class=text-danger><i>en az 2 saat önce</i></strong> yapabilirsiniz. Bunun dışında işlem <u>yapılmamaktadır.</u>',
      tabShow:{
          tabSubjectVisible:false,
          tabAudioVisible:false,
          tabVideoVisible:false,
          tabWordVisible:false,
          tabTestVisible:false,
          tabTeacherResultVisible:false,
          tabReadingVisible:false,
          tabAnimationVideoVisible:false
      }
    };
  },
  computed:{
    books:function(){
      let result=[];
      if(this.detail){
        result = this.detail.book.contents.filter(x=>x.fileType===1 || x.fileType===2);
      }
      return result;
    },
    audioDialogs:function(){
      let result=[];
      if(!this.detail){return result;}
      let contents = this.detail.book.contents.filter(x=>x.fileType===4)
      if(contents.length==0){return result;}
      
      for (let i = 0; i < contents.length; i++) {
        result.push({url:process.env.VUE_APP_ROOT_API+"Source/book/audio?fileName="+contents[i].fileName,title:contents[i].fileTitle});
      }
      return result;
    },
    videoLesson:function(){
      if(!this.detail){return null;}
      let content = this.detail.book.contents.find(x=>x.fileType===5 && x.contentType===4)
      if(!content){return null;}
      
      let url = process.env.VUE_APP_ROOT_API+"Source/book/video?fileName="+content.fileName;
      return url;
    },
    animationVideo:function(){
      if(!this.detail){return null;}
      let content = this.detail.book.contents.find(x=>x.fileType===5 && x.contentType===7)
      if(!content){return null;}
      
      let url = process.env.VUE_APP_ROOT_API+"Source/book/video?fileName="+content.fileName;
      return url;
    },
    words:function(){
      let result=[];
      if(this.detail){
        result = this.detail.book.words;
      }
      return result;
    },
    bookLevel:function(){
      let result = null;
      if(this.detail){
        result = LevelText(this.detail.book.level);
      }
      return result
    },
    bookId:function(){
      let result = null;
      if(this.detail){
        result = this.detail.book.id;
      }
      return result
    },
    bookSubject:function(){
      let result = null;
      if(this.detail){
        result = this.detail.book.subject;
      }
      return result
    },
    scheduleLessonNote:function(){
      let result = null;
      if(this.detail && this.detail.lessonNote){
        result = this.detail.lessonNote;
      }
      return result
    },
    bookResult:function(){
      let result = null;
      if(this.detail && this.detail.result){
        result = this.detail.result;
      }
      return result
    },
    bookTest:function(){
      let result = null;
      if(this.detail){
        result = this.detail.book.tests;
      }
      return result
    },
    reading:function(){
        if(this.books.length>0)
        {
            let find = this.books.find(x=>x.contentType===13);
            if(find){
                return true;
            }
        }
        return false;
    },
    lessonDate:function(){
      let result = null;
      if(this.detail && this.detail.lessonDate){
        result = this.detail.lessonDate;
      }
      return result
    },
    trainingFileId:function(){
      let result = null;
      if(this.detail && this.detail.trainingFileId){
        result = this.detail.trainingFileId;
      }
      return result
    },
    lessonStatus:function(){
      let result = 0;
      if(this.detail && this.detail.status){
          result = this.detail.status;
      }
      return result;
    },
    isPostponed:function(){
      let result = false;
      if(this.detail && this.detail.status){
        result = this.detail.status==3;
      }
      return result
    },
    isPostponeButtonDisable:function(){
      return !this.lessonStatus==0
    },
    postponeDialogContent:function(){
        return this.postponeDialogConstContent.replace('#LESSONDATE#',DateFormat(this.lessonDate,'dd.mm.yyyy'));
    },
    postponeDateView:function(){
      return DateFormat(this.lessonDate,'dd.mm.yyyy')
    }
  },
  methods: {
    ...mapActions(["GetBookDetail","PostponeLesson","SaveLessonNote"]),
    async init(){
      let toastId = LoadingToast();
      this.OpenTab('TabSubject')
      let form = {code:this.$route.params.id};
      await this.GetBookDetail(form)
      .then((res)=>{
        this.detail = res;
        UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
      })
      .catch((err)=>{
        UpdateToast(toastId,err.message,ToastType().ERROR);
      });
    },
    async PostponeLessonProcess() {
        let toastId = LoadingToast();
        let model = {
            trainingFileId: this.trainingFileId,
            lessonDate: this.lessonDate
        }
        await this.PostponeLesson(model)
        .then((res) => {
          UpdateToast(toastId,res.description,ToastType().SUCCESS);
        })
        .catch((err) => {
          UpdateToast(toastId,err.message,ToastType().ERROR);
        });
    },
    OpenClosePostponeDialogModal(visible){
      this.postponeDialogModalVisible=visible;
    },
    PostponeDialogAnswer(answer){
      if(answer){
        this.PostponeLessonProcess();
      }
    },
    OpenCloseSaveNoteDialogModal(visible){
      this.saveNoteDialogModalVisible=visible;
    },
    SaveNoteDialogAnswer(answer){
      if(answer){
        let toastId = LoadingToast();
        let model = {
            code:this.$route.params.id,
            lessonNote: this.lessonNote
        }
        this.SaveLessonNote(model)
        .then((res) => {
          UpdateToast(toastId,res.description,ToastType().SUCCESS);
        })
        .catch((err) => {
          UpdateToast(toastId,err.message,ToastType().ERROR);
        });
      }
    },
    OpenTab(value){
        this.tabShow.tabSubjectVisible=false;
        this.tabShow.tabAudioVisible=false;
        this.tabShow.tabVideoVisible=false;
        this.tabShow.tabWordVisible=false;
        this.tabShow.tabTestVisible=false;
        this.tabShow.tabTeacherResultVisible=false;
        this.tabShow.tabReadingVisible=false;
        this.tabShow.tabAnimationVideoVisible=false;
        
        if(this.$refs.AudioPlayer){
          for(let i=0;i<this.$refs.AudioPlayer.length;i++){
            this.$refs.AudioPlayer[i].pause();
          }
        }
        
        if(this.$refs.VideoPlayer){
          this.$refs.VideoPlayer.pause();
        }

        if(this.$refs.AnimationVideoPlayer){
          this.$refs.AnimationVideoPlayer.pause();
        }

        if(value==='TabSubject'){
          this.tabShow.tabSubjectVisible=true;
        } else if(value==='TabAudio'){
          this.tabShow.tabSubjectVisible=true;
          this.tabShow.tabAudioVisible=true;
        } else if(value==='TabVideo'){
          this.tabShow.tabSubjectVisible=true;
          this.tabShow.tabVideoVisible=true;
        } else if(value==='TabWord'){
          this.tabShow.tabWordVisible=true;
        } else if(value==='TabTest'){
          this.tabShow.tabTestVisible=true;
        } else if(value==='TabTeacherResult'){
          this.tabShow.tabTeacherResultVisible=true;
        } else if(value==='TabReading'){
          this.tabShow.tabReadingVisible=true;
        } else if(value==='TabAnimationVideo'){
          this.tabShow.tabSubjectVisible=true;
          this.tabShow.tabAnimationVideoVisible=true;
        }
    },
    NoteOpenClose(){
      if(this.noteVisible)
        this.noteVisible=false;
      else
        this.noteVisible=true;
    },
    SaveNoteLesson(value){
      this.lessonNote=value;
      this.OpenCloseSaveNoteDialogModal(true);
    }

  },
  mounted: async function () {
      await this.init();
    }
}
</script>
<style></style>
<template>
  <NavBar />
  <div class="container py-4">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card rounded-4 border-0">
          <div class="card-body">
            <div class="row align-items-start">
              <div class="col-lg-2 text-center text-lg-start">
                <img src="../../assets/images/home/randome-question.svg" width="100%">
              </div>
              <div class="col text-center text-lg-start">
                <div class="mt-3">
                  <span class="fw-bold text-capitalize">Rastgele Soru Oluşturucu</span><br>
                  <small class="text-capitalize">Rastgele bir soru seçin ve cevaplamaya çalışın, verilen kelimeleri kullanmayı unutmayın.</small>
                </div>
              </div>
              <div class="col-lg-2 text-center text-lg-end">
                <router-link :to="{path:'/other-tools/random-question-generator'}"  class="btn btn-sm btn-primary mt-4">İncele <i class="bi bi-arrow-right-short"></i></router-link>
              </div>
            </div>
            <hr class="border opacity-25">
            
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterInPage />
</template>

<script>
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
export default {
  name: 'OtherTools',
  data() {
    return {
    };
  },
  components: {
    NavBar,
    FooterInPage,
  },
  computed:{
  },
  methods: {
   
  },
  mounted: function() {
  }
}
</script>
<style>
</style>
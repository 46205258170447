import { service } from "@/common/axios-service";
const state = {
    user: null,
}

const getters={
    isAuthenticated: (state) => !!state.user,
    StateUser: (state) => state.user,
    NameSurname : (state) => {
      if(state.user && state.user.nameSurname){
        return state.user.nameSurname
      }else{
        return '';
      }
    }
}

const actions ={
  async Register({ dispatch }, form) {
    await service.post('register', form)
    let UserForm = new FormData()
    UserForm.append('username', form.username)
    UserForm.append('password', form.password)
    await dispatch('LogIn', UserForm)
  },

  async LogIn({ commit }, user) {
    return new Promise((resolve,reject)=>{
      service.post("Login/AccessToken", user)
      .then((res) => {
        if(res && res.data)
        {
          commit("setUser", res.data);
          resolve();
        }
        else
          reject({message:''});
        
      })
      .catch(err => {
        reject(err);
      });
    });
  },

  // async CreatePost({ dispatch }, post) {
  //   await service.post("post", post);
  //   return await dispatch("GetPosts");
  // },

  // async GetPosts({ commit }) {
  //   let response = await service.get("posts");
  //   commit("setPosts", response.data);
  // },

  async LogOut({ commit }) {
    let user = null;
    commit("logout", user);
  },

  async ForgotPassword(context,form) {
    return new Promise((resolve,reject)=>{
      service.post("Login/ForgotPassword", form)
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  async SavePassword(context,form) {
    return new Promise((resolve,reject)=>{
      service.post("Login/SavePassword", form)
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },

}

const mutations ={
      setUser(state, user) {
        state.user = user;
      },
      // setPosts(state, posts) {
      //   state.posts = posts;
      // },
      logout(state, user) {
        state.user = user;
      },
}

export default{
    state,
    getters,
    actions,
    mutations
};
<template>
  <NavBar />
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-9">
          <div class="card rounded-3 border-0">
            <div class="card-body">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="lessoncalendar-tab" data-bs-toggle="tab" data-bs-target="#lessoncalendar" type="button"
                  role="tab" aria-controls="lessoncalendar" aria-selected="false">Ders Takvimi</button>
              </li>
              <!-- <li class="nav-item" role="presentation">
                <button class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
                  role="tab" aria-controls="home" aria-selected="true">Ders</button>
              </li> -->
              <!-- <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
                  role="tab" aria-controls="profile" aria-selected="false">İstatistik</button>
              </li> -->
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active pt-3" id="lessoncalendar" role="tabpanel" aria-labelledby="lessoncalendar-tab">
                  <TrainingCalender />
                </div>
                <!-- <div class="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div class="card mb-2" v-for="n in 6" :key="n">
                    <div class="row g-0 mb-2 border-bottom">
                      <div class="col-md-2">
                        <img :src="require('@/assets/images/lesson-' + n + '.jpg')" class="img-fluid rounded-start"
                          style="height: 90px;" alt="...">
                      </div>
                      <div class="col-md-10">
                        <div class="card-body p-2">
                          <div class="row">
                            <div class="col-8">
                              <h5 class="fw-bold">GENEL İNGİLİZCE - {{ n }}</h5>
                              <small>Bu ders selamlaşma, kibar ifadeler ve sayılar gibi konuların yanı sıra yeni ...</small>
                            </div>
                            <div class="col-4">
                              <span>%25</span><span class="text-muted float-end">12 Ünite</span>
                              <div class="progress mt-1 mb-2" style="height: 6px;">
                                <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25"
                                  aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <span class="badge rounded-pill bg-primary me-1 float-end">A1 - Başlangıç</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card border-0">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item" v-for="u in 5" :key="u">
                          <span class="float-start">Unit-{{ u }} : An item</span>
                          <router-link :to="{path:'/course'}"><i class="bi bi-play-circle float-end ms-1" style="font-size: 1.5rem;"></i></router-link>
                          <i v-if="u % 2 === 0" class="bi bi-check2-circle float-end text-success" style="font-size: 1.5rem;"></i>
                        </li>
                        <li class="list-group-item text-center">
                          <a href="javascript" class="text-decoration-none text-black-50"><small>Daha Fazla Göster</small></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="tab-pane fade pt-3" id="profile" role="tabpanel" aria-labelledby="profile-tab">İstatistikler</div> -->
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <FooterInPage />
</template>

<script>
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
import TrainingCalender from '@/components/MyPage/training-calender.vue'
export default {
  name: 'MyPageDashboard',
  components: {
    NavBar,
    FooterInPage,
    TrainingCalender
  }
}
</script>
<style></style>
import { service } from "@/common/axios-service";
const state = {
}

const getters={
}

const actions ={
  async GetTrainingSchedule(context,form) {
    return new Promise((resolve,reject)=>{
      service.post("Student/GetSchedule",form)
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  async GetBookDetail(context,form) {
    return new Promise((resolve,reject)=>{
      service.post("Student/BookDetail",form)
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  async PostponeLesson(context,form) {
    return new Promise((resolve,reject)=>{
      service.post("Student/PostponeLesson",form)
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  async GetTrainingPackageInfo() {
    return new Promise((resolve,reject)=>{
      service.post("Student/TrainingPackageInfo",{})
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  async SaveAnswerBookTest(context,form) {
    return new Promise((resolve,reject)=>{
      service.post("Student/BookTestResultSave",form)
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  async SaveLessonNote(context,form) {
    return new Promise((resolve,reject)=>{
      service.post("Student/LessonNoteSave",form)
      .then((res) => {
        if(res && res.data)
          resolve(res.data);
        else
          reject({message:''});
      })
      .catch(err => {
        reject(err);
      });
    });
  },
}

const mutations ={
}

export default{
    state,
    getters,
    actions,
    mutations
};
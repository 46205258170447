<template>
  <div class="modal fade" tabindex="-1" v-bind:id="ModalName" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5 text-uppercase">{{ Title }}</h1>
          <button type="button" class="btn-close" @click="CloseModal()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6">
                  <audio v-if="IsAudio" controls controlsList="nodownload" v-bind:src="Audio"></audio>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 text-end">
                  <a v-if="IsTranslate" v-bind:href="TranslateLink()" class="btn btn-primary mt-2" target="_blank"><i class="bi bi-translate"></i> Google Translate</a>
              </div>
          </div>
          <div class="text-center mt-2">
            <a v-if="IsImage" v-bind:href="Image" target="_blank"><img v-bind:src="Image" class="img-fluid" alt="{{Title}}"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import bootstrapBundle from 'bootstrap/dist/js/bootstrap.bundle';
  export default {
    name: "ExcerciseModal",
    props: {
      ModalName:{
        type:String,
        default:'ModalExcercise'
      },
      Visible:{
        type:Boolean,
        default:false
      },
      Category:{
        type:Number,
        default: 0
      },
      Sequence:{
        type:Number,
        default: 0
      },
      Title:{
        type:String,
        default: null
      },
      Content:{
        type:String,
        default: null
      },
      IsImage:{
        type:Boolean,
        default: false
      },
      IsAudio:{
        type:Boolean,
        default: false
      },
      IsTranslate:{
        type:Boolean,
        default: false
      }
    },
    data() {
      return {
        modalElement:null
      };
    },
    computed: {
      Image(){
          let result ='';
          if(this.Category!=0 && this.Sequence!=0){
            result=process.env.VUE_APP_ROOT_API+ 'Source/exercise/image/?category=' + this.Category + '&number=' + this.Sequence;
          }
          return result;
      },
      Audio(){
          let result ='';
          if(this.Category!=0 && this.Sequence!=0){
            result=process.env.VUE_APP_ROOT_API+ 'Source/exercise/audio/?category=' + this.Category + '&number=' + this.Sequence;
          }
          return result;
      },
    },
    methods: {
      TranslateLink() {
          const GOOGLE_TRANSLATE_BASE_URL = "https://translate.google.com/?sl=en&tl=tr&text=";
          if (this.Content == '')
              return 'javascript:;';
          let categoriArray = [20,21,22,23,24,25,26,27,28,29,30,31,32];
          let content = this.Content;
          if (categoriArray.find(x=>x==parseInt(this.Category))) {
              content = content.replace(/ A:/gi, '%0AA:');
              content = content.replace(/ B:/gi, '%0AB:');
          }
          return GOOGLE_TRANSLATE_BASE_URL + content;
      },
      CloseModal(){
        this.modalElement.hide();
        this.$emit("CloseModal", false);
      }
    },
    watch: {
      Visible(value){
        if(value){
          this.modalElement.show();
        }
      }
    },
    mounted: function() {
      let elementName = this.ModalName;
      this.modalElement = new bootstrapBundle.Modal(document.getElementById(elementName),{backdrop: 'static',keyboard: false});
    }
  };
  </script>
  
  <style>
  </style>
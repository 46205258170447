<template>
    <div v-if="this.BookResult==null" class="text-center pt-5">
        <span class="fs-5">Henüz öğretmen değerlendirmesi girilmedi.</span>
    </div>
    <div v-else>
        <div class="row mb-3">
            <div class="col-6">
                <div class="mb-1 p-2 border rounded">
                    <i class="bi bi-ear"></i> Dinleme : <span class="text-uppercase fw-bold" v-html="listening"></span>
                </div>
                <div class="mb-1 p-2 border rounded">
                    <i class="bi bi-book"></i> Okuma : <span class="text-uppercase fw-bold" v-html="reading"></span>
                </div>
                <div class="mb-1 p-2 border rounded">
                    <i class="bi bi-people"></i> Konuşma : <span class="text-uppercase fw-bold" v-html="speaking"></span>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-1 p-2 border rounded">
                    <i class="bi bi-journal-text"></i> Kelime Bilgisi : <span class="text-uppercase fw-bold" v-html="vocabulary"></span>
                </div>
                <div class="mb-1 p-2 border rounded">
                    <i class="bi bi-card-text"></i> Dil Bilgisi : <span class="text-uppercase fw-bold" v-html="grammar"></span>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <div v-html="note"></div>
            </div>
        </div>
        <div v-if="inCorrects!=null">
            <div class="row border-bottom">
                <div class="col-6"><strong>Yanlış</strong></div>
                <div class="col-6"><strong>Doğru</strong></div>
            </div>
            <div class="row" v-for="data in inCorrects" :key="data">
                <div class="col-6 text-danger">{{ data.InCorrect }}</div>
                <div class="col-6 text-success">{{ data.Correct }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import {ResultPuanText} from '@/common/enum-helper';
export default {
    name: "TabResultSubject",
    props: {
        BookResult:{
            type:Object,
            default:null
        }
    },
    components: {},
    data() {return {}},
    computed: {
        note(){
            if(this.BookResult!=null){
                return this.BookResult.note;
            }
            return null;
        },
        listening(){
            if(this.BookResult!=null){
                return ResultPuanText(this.BookResult.listening);
            }
            return null;
        },
        reading(){
            if(this.BookResult!=null){
                return ResultPuanText(this.BookResult.reading);
            }
            return null;
        },
        speaking(){
            if(this.BookResult!=null){
                return ResultPuanText(this.BookResult.speaking);
            }
            return null;
        },
        vocabulary(){
            if(this.BookResult!=null){
                return ResultPuanText(this.BookResult.vocabulary);
            }
            return null;
        },
        grammar(){
            if(this.BookResult!=null){
                return ResultPuanText(this.BookResult.grammar);
            }
            return null;
        },
        inCorrects(){
            if(this.BookResult!=null){
                return JSON.parse(this.BookResult.inCorrects);
            }
            return null;
        }
    },
    methods: {
    },
    mounted: function () {

    }
};
</script>
    
<style>

</style>
<template>
    <div>
        <div class="row mb-3">
          <div class="col-2"><a class="btn btn-primary btn-sm float-start" href="javascript:;" :disabled="loadingButtonDisable" @click="prevMonth()"><i class="bi bi-caret-left"></i></a></div>
          <div class="col-8 text-center"><span class="align-middle h4">{{ selectedMonthText }} / {{selectedYear}}</span></div>
          <div class="col-2"><a class="btn btn-primary btn-sm float-end" href="javascript:;" :disabled="loadingButtonDisable" @click="nextMonth()"><i class="bi bi-caret-right"></i></a></div>
        </div>
        <ul class="list-group">
          <li class="list-group-item d-flex flex-sm-row flex-column" v-for="d in dayInMonth" :key="d">
            <div class="text-center bg-light mb-2 mb-lg-0" style="min-width:40px">
              <h3 class="mb-0" v-html="(d.dayNumber<10)?'0'+d.dayNumber:d.dayNumber"></h3>
              <small>{{ d.dayText }}</small>
            </div>
            <div class="flex-grow-1 ps-3 align-self-center text-center text-lg-start mb-2 mb-lg-0">
              <div v-if="!d.isWeekEnd">
                <div class="text-capitalize"><small><i>{{d.bookTitle}}</i></small></div>
                <div class="text-capitalize">{{ d.bookLevel }} {{ d.unitNumber }} {{d.subjectTitle}}</div>
                <div>
                  <i v-if="d.hasWord" class="bi bi-file-word-fill me-1"></i>
                  <i v-if="d.hasAudio" class="bi bi-headphones me-1"></i>
                  <i v-if="d.hasVideo" class="bi bi-play-btn me-1"></i>
                  <i v-if="d.hasTest" class="bi bi-stopwatch-fill me-1"></i>
                  <i v-if="d.hasLessonNote" class="bi bi-journal-bookmark-fill"></i>
                </div>
              </div>
            </div>
            <div class="align-self-center pe-2 text-center text-lg-start mb-2 mb-lg-0" v-if="!d.isWeekEnd">
              <span class="h3 text-success"  v-if="d.lessonStatus===2"><i class="bi bi-check-circle"></i></span>
              <span class="h3 text-danger"  v-else-if="d.lessonStatus===1"><i class="bi bi-x-circle"></i></span>
              <span class="h3 text-warning"  v-else-if="d.lessonStatus===3"><i class="bi bi-arrow-clockwise"></i></span>
            </div>
            <div class="align-self-center  text-center text-lg-start" v-if="!d.isWeekEnd">
              <button v-if="d.lessonStatus==0" @click="OpenClosePostponeDialogModal(true,d)" :disabled="loadingButtonDisable" class="btn btn-primary btn-sm me-1">Ertele</button>
              <router-link v-if="d.lessonStatus>-1" :to="{path:'/courses/'+CreateUrl(d.bookTitle)+'/'+d.bookId}" href="javascript:;" :disabled="loadingButtonDisable" class="btn btn-sm btn-primary">Derse Git <i class="bi bi-arrow-right-short"></i></router-link>
            </div>
          </li>
          
        </ul>
    </div> 
    <PostponeDialog 
    :ShowCloseButton="false"
    :Title="postponeDialog.title"
    :Content="postponeDialogContent" 
    :Visible="postponeDialog.postponeDialogModalVisible" 
    @CloseModal="OpenClosePostponeDialogModal($event,null)"
    @CustomDialogAnswer="PostponeDialogAnswer($event)"
    />
  </template>
  <script>
  import {mapActions } from "vuex";
  import {DateFormat} from '@/common/datetime-helper';
  import{CleanUrl} from '@/common/url-helper';
  import { LoadingToast,UpdateToast,ToastType } from "@/common/notification.js";
  import PostponeDialog from "@/components/Modals/CustomDialogModal.vue";
  export default {
    name: "TrainingCalender",
    components:{
      PostponeDialog
    },
    data() {
      return {
        dayInMonth:[],
        selectedMonthText:'',
        selectedMonth: parseInt(new Date().getMonth()),
        selectedYear: parseInt(new Date().getFullYear()),
        schedule:null,
        loadingButtonDisable:false,
        postponeDialog:{
          postponeDialogModalVisible:false,
          trainingFileId:'',
          lessonDate:'',
          title:'Uyarı',
          constContent:'<p>#LESSONDATE# tarihli dersinizi ertelemek istiyor musunuz?</p><strong>Not:</strong> Ders erteleme işlemini, ders saatinizden <strong class=text-danger><i>en az 2 saat önce</i></strong> yapabilirsiniz. Bunun dışında işlem <u>yapılmamaktadır.</u>',
        }
      }
    },
    computed: {
      dayShortTextArr : ()=> ['PAZ', 'PZT', 'SAL', 'ÇAR', 'PER', 'CUM', 'CMT'],
      monthTextArr:()=> ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
      postponeDialogContent:function(){
          return this.postponeDialog.constContent.replace('#LESSONDATE#',DateFormat(this.postponeDialog.lessonDate,'dd.mm.yyyy'));
      },
    },
    methods: {
      ...mapActions(["GetTrainingSchedule","PostponeLesson"]),
      OpenClosePostponeDialogModal(visible,p){
        if(p!=null){
          this.postponeDialog.lessonDate = p.date;
          this.postponeDialog.trainingFileId = p.trainingFileId;
        }
        this.postponeDialog.postponeDialogModalVisible=visible;
      },
      PostponeDialogAnswer(answer){
        if(answer){
          this.PostponeLessonProcess();
        }
      },
      async PostponeLessonProcess() {
          this.loadingButtonDisable=true;
          let toastId = LoadingToast();
          let model = {
              trainingFileId: this.postponeDialog.trainingFileId,
              lessonDate: this.postponeDialog.lessonDate
          }
          await this.PostponeLesson(model)
          .then((res) => {
            UpdateToast(toastId,res.description,ToastType().SUCCESS);
            this.refreshMonth();
            this.loadingButtonDisable=false;
          })
          .catch((err) => {
            UpdateToast(toastId,err.message,ToastType().ERROR);
            this.loadingButtonDisable=false;
          });
      },
      CreateUrl(value){
        return CleanUrl(value);
      },
      async getSchedule(){
          let toastId = LoadingToast();
          let startDate = this.selectedYear + "-" + (parseInt(this.selectedMonth) + 1) + "-01";
          let form = {date:startDate};
          await this.GetTrainingSchedule(form)
          .then((res)=>{
            this.schedule = res;
            UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
          })
          .catch((err)=>{
            UpdateToast(toastId,err.message,ToastType().ERROR);
          });
      },
      async init(){
        await this.getSchedule();
        this.selectedMonthText = this.monthTextArr[this.selectedMonth];
        this.dayInMonth=[];
        let firstDay = new Date(this.selectedYear, this.selectedMonth, 1);
        let lastDay = new Date(this.selectedYear, this.selectedMonth+1, 0);
        let bookId='',bookTitle='',subjectTitle='',bookLevel='',unitNumber='',hasVideo=false,hasAudio=false,hasTest=false,hasWord=false,lessonStatus=-1,trainingFileId
='',hasLessonNote=false;
        for (var i = firstDay; i <= lastDay; i.setDate(i.getDate() + 1)) {
          trainingFileId='';
          bookId='';
          bookTitle='';
          subjectTitle='';
          bookLevel='';
          unitNumber='';
          lessonStatus=-1;
          hasVideo=false;
          hasAudio=false;
          hasTest=false;
          hasWord=false;
          hasLessonNote=false;
          if(this.schedule){
            let findLesson = this.schedule.find(x=>DateFormat(x.date,'yyyy-mm-dd')===DateFormat(i,'yyyy-mm-dd'));
            if(findLesson){
              bookLevel = findLesson.book.bookLevel;
              unitNumber = "Ünite-"+findLesson.book.unitNumber+":";
              bookId = findLesson.bookId;
              bookTitle = findLesson.book.bookName;
              subjectTitle = '"'+findLesson.book.subject+'"';
              lessonStatus = findLesson.status;
              hasVideo=findLesson.book.hasVideo;
              hasAudio=findLesson.book.hasAudio;
              hasTest=findLesson.book.hasTest;
              hasWord=findLesson.book.hasWord;
              trainingFileId = findLesson.trainingFileId;
              hasLessonNote = findLesson.hasLessonNote;
            }
          }
          
          this.dayInMonth.push({
            date : DateFormat(i, 'yyyy-mm-dd'), 
            dayNumber : i.getDate(),
            dayText : this.dayShortTextArr[new Date(i).getDay()],
            isWeekEnd : ([0,6].indexOf(new Date(i).getDay())<0?false:true),
            isHoliday:false,
            lessonStatus:lessonStatus,
            bookId:bookId,
            bookTitle :bookTitle,
            subjectTitle:subjectTitle,
            bookLevel : bookLevel,
            unitNumber : unitNumber,
            hasVideo : hasVideo,
            hasAudio : hasAudio,
            hasTest : hasTest,
            hasWord : hasWord,
            trainingFileId:trainingFileId,
            hasLessonNote : hasLessonNote
          });
        }
      },
      nextMonth() {
          let month = this.selectedMonth + 1;
          if (month > 11) {
              this.selectedMonth = 0;
              this.selectedYear++;
          } else {
              this.selectedMonth = month;
          }
          this.init();
      },
      prevMonth() {
          let month = this.selectedMonth - 1;
          if (month < 0) {
              this.selectedMonth = 11;
              this.selectedYear--;
          } else {
              this.selectedMonth = month;
          }
          this.init();
      },
      refreshMonth() {
          this.init();
      },
    },
    mounted: function () {
      this.init();
    }
  };
  </script>
    
  <style></style>
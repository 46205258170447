<template>
  <NavBar />
  <div class="container py-4">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card rounded-3 border-0">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-lg-7 text-center">
                <h4 class="mt-4">Paket Bilgileri</h4>
                <p class="display-6 fs-4">{{packageName}}</p>
              </div>
              <div class="col-lg-5"><img src="../assets/images/training/package.svg" style="width: 100%;"></div>
            </div>
            <hr class="border border-1 opacity-25 mt-5 mb-5">
            <div class="text-center mt-4 mb-4">
              <h4>Kalan Haklar</h4>
              <div class="row justify-content-center mt-4">
                <div class="col-lg-3">
                  <div class="display-6 fs-4 mb-2">Ders Adedi</div>
                  <div id="LessonCount"></div>
                </div>
                <div class="col-lg-3 text-center">
                  <div class="display-6 fs-4 mb-2">Ders Erteleme</div>
                  <div id="PostponeCount"></div>
                </div>
                <div class="col-lg-3 text-center">
                  <div class="display-6 fs-4 mb-2">Ders Dondurma</div>
                  <div id="FreezeCount"></div>
                </div>
              </div>
              <div class="display-6 fs-5 mb-2">Sınırsız öğrenci ekranı kullanımı.</div>
              <div class="display-6 fs-5 mb-2">Öğrenci ekranılarına 7/24 erişim.</div>
              <div class="display-6 fs-5 mb-2">Ücretsiz İngilizce eğitim materyalleri.</div>
              <div class=" fs-6 mt-5">6 aylık eğitim programlarında 1 kez en çok 2 aylık olmak koşuluyla ders dondurma hakkına sahiptir,<br>12 aylık eğitim programlarında 1 kez, en çok 4 aylık olmak koşuluyla ders dondurma hakkına sahiptir.<br><u>Ders dondurma hakkı sadece 1 kez kullanılabilen bir haktır, parça parça kullanılamaz</u>.</div>

            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterInPage />
</template>

<script>
import { mapActions } from "vuex";
import {circliful} from 'js-plugin-circliful';
import { LoadingToast,UpdateToast,ToastType } from "@/common/notification.js";
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
export default {
  name: 'TrainingFile',
  data() {
    return {
      packageName:'',
    };
  },
  components: {
    NavBar,
    FooterInPage,
  },
  computed:{
  },
  methods: {
    ...mapActions(["GetTrainingPackageInfo"]),
    async GetTrainingFile(){
      let toastId = LoadingToast();
      await this.GetTrainingPackageInfo()
      .then((res)=>{
        this.CreateChart('LessonCount',res.lessonCount,res.lessonRate);
        this.CreateChart('PostponeCount',res.postponeCount,res.postponeRate);
        this.CreateChart('FreezeCount',res.freezeCount,res.freezeRate);
        
        this.packageName=res.packageName;
        UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
      })
      .catch((err)=>{
        UpdateToast(toastId,err.message,ToastType().ERROR);
      });
    },
    CreateChart(id,value,rate){
      circliful.newCircle({
        percent: rate,
        id: id,
        type: 'half',
        foregroundCircleWidth: 10,
        backgroundCircleWidth: 5,
        text: value,
        textReplacesPercentage: true,
        strokeLinecap: "round",
      });
    }
  },
  mounted: function() {
    this.GetTrainingFile();
  }
}
</script>
<style>
  @import 'js-plugin-circliful/dist/main.css';
  #FreezeCount .foreground-circle, #PostponeCount .foreground-circle, #LessonCount .foreground-circle {
    stroke: #55b948;
  }
</style>
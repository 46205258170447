<template>
  <div class="modal fade" tabindex="-1" v-bind:id="ModalName" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5 text-uppercase">{{ Title }}</h1>
          <button type="button" class="btn-close" @click="CloseModal()"></button>
        </div>
        <div class="modal-body">
            <table class="table">
              <thead>
                <tr>
                  <th>Tarih</th>
                  <th style="width: 100px;" class="text-center">Doğru</th>
                  <th style="width: 100px;" class="text-center">Yanlış</th>
                  <th v-if="IsLevelTest" style="width: 100px;" class="text-center">Seviye</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="r in exercises" :key="r">
                  <td>{{ DateFormatWithOffset(r.startDate) }}</td>
                  <td class="text-center">{{ r.currectNumber }}</td>
                  <td class="text-center">{{ r.inCurrectNumber }}</td>
                  <td v-if="IsLevelTest" class="text-center">{{ GetLevelTestPuan(r.currectNumber) }}</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {GetLevelTextByCurrectPuan} from '@/common/test-helper';
  import {DateFormat, CalculateDateTime} from '@/common/datetime-helper';
  import bootstrapBundle from 'bootstrap/dist/js/bootstrap.bundle';
  export default {
    name: "ExerciseResultModal",
    components:{
    },
    props: {
      ModalName:{
        type:String,
        default:'ModalExerciseResult'
      },
      Visible:{
        type:Boolean,
        default:false
      },
      Title:{
        type:String,
        default: null
      },
      ExcerciseResultData:{
        type:Object
      },
      IsLevelTest:{
        type:Boolean,
        default:false
      },
    },
    data() {
      return {
        modalElement:null,
      };
    },
    computed: {
      exercises(){
        let result = [];
        if(this.ExcerciseResultData && this.ExcerciseResultData.length>0){
            result = this.ExcerciseResultData;
        }
        return result;
      },
    },
    methods: {
      CloseModal(){
        this.modalElement.hide();
        this.$emit("CloseModal", false);
      },
      DateFormatWithOffset(value) {
          return DateFormat(CalculateDateTime(value), 'dd.mm.yyyy hh:MM:ss');
      },
      GetLevelTestPuan(value) {
          return GetLevelTextByCurrectPuan(value);
      }
    },
    watch: {
      Visible(value){
        if(value){
          this.modalElement.show();
        }
      }
    },
    mounted: function() {
      let elementName = this.ModalName;
      this.modalElement = new bootstrapBundle.Modal(document.getElementById(elementName),{backdrop: 'static',keyboard: false});
    }
  };
  </script>
  
  <style>
  </style>
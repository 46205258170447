<template>
    <div class="d-flex align-items-center py-4">
      <div class="form-recover-pasword rounded-3 bg-white w-100 m-auto">
          <h3 class="text-center">Parolayı Sıfırla</h3>
          <p class="text-center">Parolanız sadece rakamlardan ve en az 4, en fazla 8 karakterden oluşmalıdır.</p>
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="password" v-model="form.password" placeholder="Password" autocomplete="new-password" >
            <label for="password">Yeni Parola</label>
          </div>
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="passwordconfirm" v-model="form.passwordConfirm" placeholder="Password" autocomplete="current-password" >
            <label for="passwordconfirm">Yeni Parolayı Onayla</label>
          </div>
          <button class="btn btn-primary w-100 py-2" type="button" @click="submit()" :disabled="form.isLoading">Gönder</button>
      </div>
    </div>
    <div><VersionNumber /></div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import VersionNumber from "@/components/Shared/VersionNumber.vue";
  import { LoadingToast,UpdateToast,ToastType,ErrorToast } from "@/common/notification.js";
  import {IsPasswordValid} from "@/common/validation-password";
  export default {
    name: "RecoverPasswordPage",
    components: {
      VersionNumber
    },
    data() {
      return {
        form: {
          password: "",
          passwordConfirm: "",
          code:this.$route.params.code,
          isLoading:false
        }
      };
    },
    methods: {
      ...mapActions(["SavePassword"]),
      async submit() {
        if(!this.isFormValid()){
          return;
        }

        let toastId = LoadingToast();
        this.form.isLoading=true;
        const formModel = {
          "SecureCode": this.form.code,
          "Password": this.form.password,
          "PanelType": 2
        };
        await this.SavePassword(formModel)
        .then((res)=>{
          this.form.isLoading=false;
          UpdateToast(toastId,res.description,ToastType().SUCCESS);
          setTimeout(() => {
            this.$router.push("/");  
          }, 1000);
        })
        .catch(err=>{
          UpdateToast(toastId,err.message,ToastType().ERROR);
          this.form.isLoading=false;
        });
      },
      isFormValid(){
        if (this.form.password.length < 4 || this.form.password.length>8) {
            ErrorToast('Parolanız en az 4 en fazla 8 karakterden oluşmalıdır.');
            return false;
        }
        if (!IsPasswordValid(this.form.password)) {
            ErrorToast('Hatalı parola girdiniz.');
            return false;
        }
        if (this.form.password !== this.form.passwordConfirm) {
            ErrorToast('Parolanızı onaylayınız.');
            return false;
        }

        return true
      }
    }
  };
  </script>
  
  <style scoped>
    .form-recover-pasword {
      max-width: 330px;
      padding: 1rem;
    }
    .form-recover-pasword img{
      margin: 0 auto 15px;
      display: block;
    }
    .form-recover-pasword .form-floating:focus-within {
      z-index: 2;
    }
  </style>
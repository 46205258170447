<template>
   <div class="row justify-content-center mt-5">
      <div class=" col-xs-11 col-sm-11 col-md-5 col-lg-4">
          <div class="rounded-4 shadow border">
            <div class="p-5 pb-4 border-bottom-0">
              <img src="../assets/images/lengedu.jpg" class="mx-auto d-block">
            </div>
            <div class="p-5 pt-0">
              <form class="">
                <div class="form-floating">
                  <input type="text" class="form-control" id="NameSurname" placeholder="">
                  <label for="NameSurname">Ad-Soyad</label>
                </div>
                <div class="form-floating">
                  <input type="text" class="form-control" id="Phone" placeholder="">
                  <label for="Phone">Telefon</label>
                </div>
                <div class="form-floating">
                  <input type="password" class="form-control" id="Mail" placeholder="">
                  <label for="password">E-posta adresi</label>
                </div>
                <div class="form-check text-start my-3">
                  <input class="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                    <a href="javascript:;" class="fw-bold text-blue">Kullanım Şartlarını</a> kabul ediyorum.
                  </label>
                </div>
                <button class="btn btn-primary w-100 py-2" type="submit">Ücretsiz Kaydolun</button>
                <p class="small mt-3">Kayıt olarak, kişisel verilerin işlenmesi hakkında <a href="javascript:;" class="fw-bold text-black">Aydınlatma Metnini</a> ve <a href="javascript:;" class="fw-bold text-black">Gizlilik Politikasını</a> kabul etmiş sayılırsınız. "Aydınlatma Metnine" ve "Gizlilik Politikasına" alt menüde bulunan ilgili bağlantılara tıklayarak her zaman ulaşabilirsiniz.</p>
              </form>
            </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  
  export default {
    name: "RegisterPage",
    components: {},
    data() {
      return {
        form: {
          username: "",
          full_name: "",
          password: "",
        },
        showError: false
      };
    },
    methods: {
      ...mapActions(["Register"]),
      async submit() {
        try {
          await this.Register(this.form);
          this.$router.push("/posts");
          this.showError = false
        } catch (error) {
          this.showError = true
        }
      },
    },
  };
  </script>
  <style scoped>
    input[id="NameSurname"] {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    input[id="Phone"] {
      margin-bottom: -1px;
      border-radius: 0;
    }

    input[id="Mail"] {
      margin-bottom: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  </style>
<template>
  <div class="modal fade" tabindex="-1" v-bind:id="ModalName" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5 text-uppercase">{{ Title }}</h1>
          <button type="button" class="btn-close" @click="CloseModal()"></button>
        </div>
        <div class="modal-body">
            <ExcerciseTest v-show="!showResult"
              :Visible="Visible"
              :ExcerciseData="excerciseData"
              @SaveAnswer="SaveAnswer($event)"
            />
            <ExcerciseTestResult2 v-show="showResult"
              :ExcerciseData="excerciseData"
              :ExcerciseUserAnswerData="excerciseUserAnswerData"
              :ShowLevel=IsLevelTest
            />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from "vuex";  
  import bootstrapBundle from 'bootstrap/dist/js/bootstrap.bundle';
  import ExcerciseTest from '@/components/Excercise/ExcerciseTest.vue';
  import ExcerciseTestResult2 from '@/components/Excercise/ExcerciseTestResult2.vue';
  import { LoadingToast,UpdateToast,ToastType } from "@/common/notification.js";
  export default {
    name: "TestModal",
    components:{
      ExcerciseTest,
      ExcerciseTestResult2
    },
    props: {
      ModalName:{
        type:String,
        default:'ModalTest'
      },
      Visible:{
        type:Boolean,
        default:false
      },
      Title:{
        type:String,
        default: null
      },
      ExcerciseData:{
        type:Object
      },
      ExcerciseId:{
        type:String,
        default:''
      },
      IsLevelTest:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        modalElement:null,
        excerciseData:[],
        excerciseUserAnswerData:{},
        showResult:false
      };
    },
    computed: {
    },
    methods: {
      ...mapActions(["SaveAnswerTest"]),
      CloseModal(){
        this.modalElement.hide();
        this.$emit("CloseModal", false);
      },
      async SaveAnswer(form){    
        form.testId=this.ExcerciseId;
        this.excerciseUserAnswerData=form;
        let toastId = LoadingToast();
        await this.SaveAnswerTest(form)
        .then((res)=>{
          this.tests = res;
          UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
        })
        .catch((err)=>{
          UpdateToast(toastId,err.message,ToastType().ERROR);
        });

        this.showResult=true;
      }
    },
    watch: {
      Visible(value){
        if(value){
          this.excerciseData = this.ExcerciseData;
          this.excerciseUserAnswerData=undefined;
          this.showResult=false;
          this.modalElement.show();
        }
      }
    },
    mounted: function() {
      let elementName = this.ModalName;
      this.modalElement = new bootstrapBundle.Modal(document.getElementById(elementName),{backdrop: 'static',keyboard: false});
    }
  };
  </script>
  
  <style>
  </style>
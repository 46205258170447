import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import profile from './modules/profile';
import training from './modules/training';
import exercise from './modules/excercise';
import test from './modules/test';
import dashboard from './modules/dashboard';
import othertool from './modules/othertool';

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    training,
    exercise,
    test,
    dashboard,
    othertool
  },
  plugins: [createPersistedState()]
});
function MonthText(value){
    let monthIndex = parseInt(value,10);
    let monthArr=["ocak","şubat","mart","nisan","mayıs","haziran","temmuz","ağustos","eylül","ekim","kasım","aralık"];
    if(parseInt(value,10)>0 && parseInt(value,10)<13){
        return monthArr[monthIndex-1];
    }
    return '';
}

function DayOfTheWeekText(value){
    let dayOfTheWeekIndex = parseInt(value,10);
    let weekday=["pazar","pazartesi","salı","çarşamba","perşembe","cuma","cumartesi"];
    if(parseInt(value,10)>=0 && parseInt(value,10)<=6){
        return weekday[dayOfTheWeekIndex];
    }
    return '';
}

function DateFormat(value, format, useUTC = false) {
    try {
        let date = new Date(value);
        let d = useUTC ? date.getUTCDate() : date.getDate();
        let m = (useUTC ? date.getUTCMonth() : date.getMonth()) + 1;
        let y = useUTC ? date.getUTCFullYear() : date.getFullYear();
        let h = useUTC ? date.getUTCHours() : date.getHours();
        let M = useUTC ? date.getUTCMinutes() : date.getMinutes();
        let s = useUTC ? date.getUTCSeconds() : date.getSeconds();
        let w = date.getDay();

        d = d < 10 ? '0' + d : d;
        m = m < 10 ? '0' + m : m;
        h = h < 10 ? '0' + h : h;
        M = M < 10 ? '0' + M : M;
        s = s < 10 ? '0' + s : s;

        switch (format) {
            case 'dd/mm':
                return d + '/' + m;
            case 'yyyy-mm-dd':
                return y + '-' + m + '-' + d;
            case 'dd.mm.yyyy':
                return d + '.' + m + '.' + y;
            case 'dd.mm.yyyy hh:MM:ss':
                return d + '.' + m + '.' + y + ' ' + h + ':' + M + ':' + s;
            case 'yyyy-mm-dd hh:MM:ss':
                return y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s;
            case 'hh:MM:ss':
                return h + ':' + M + ':' + s;
            case 'dd MM DD':
                    return d + ' ' + MonthText(m) + ' '+ y + ' '+DayOfTheWeekText(w);
            default:
                return y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s;
        }
    } catch {
        return value;
    }
}

function CalculateTime(time) {
    var timeOffset = "+03:00";
    var offsetArr = timeOffset.split(':');
    var offsetHour = offsetArr[0];
    var offsetMinute = offsetArr[1];
    var datetime = new Date('2020-01-10 ' + time + ':00');
    var timeHour = datetime.getHours();
    var timeMinute = datetime.getMinutes();

    datetime = new Date(datetime.setHours(parseInt(timeHour) + parseInt(offsetHour)));
    datetime = new Date(datetime.setMinutes(parseInt(timeMinute) + parseInt(offsetMinute)));

    var realHour = datetime.getHours();
    var realMinute = datetime.getMinutes();

    realHour = realHour < 10 ? '0' + realHour : realHour;
    realMinute = realMinute < 10 ? '0' + realMinute : realMinute;

    return realHour + ':' + realMinute;
}

function CalculateDateTime(dateTime) {
    var timeOffset = "+03:00";
    var offsetArr = timeOffset.split(':');
    var offsetHour = offsetArr[0];
    var offsetMinute = offsetArr[1];

    var date = new Date(dateTime);
    var timeHour = date.getHours();
    var timeMinute = date.getMinutes();

    date = new Date(date.setHours(parseInt(timeHour) + parseInt(offsetHour)));
    date = new Date(date.setMinutes(parseInt(timeMinute) + parseInt(offsetMinute)));
    return date;
}

function ConvertMiliSecoundToSecound(value) {
    if (value < 1)
        return '00:00:00';

    let seconds = Math.floor(value / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    return `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + seconds}`;
}

export { DateFormat, CalculateTime, CalculateDateTime, ConvertMiliSecoundToSecound }
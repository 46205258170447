<template>
  <NavBar />
  <div class="container py-4">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card rounded-3 border-0">
          <div class="card-body">
            <h3 class="text-secondary-emphasis mb-3 mt-5  text-center">Arkadaşını Davet Et 1 Ay Hediye Eğitim Kazan</h3>
            <div class="row g-4 row-cols-1 row-cols-lg-3 mb-5">
              <div class="feature col text-center">
                <img src="../assets/images/invite/share.svg" width="100%">
                <h3 class="fs-5 text-body-emphasis">Form Oluştur</h3>
                <p>Arkadaşlarının bilgilerini eksiksiz girerek form oluştur. Danışmanlarımız arkadaşlarınla iletişime geçsin.</p>
              </div>
              <div class="feature col text-center">
                <img src="../assets/images/invite/spirit.svg" width="100%">
                <h3 class="fs-5 text-body-emphasis">Arkadaşlarını İngilizce Öğrenmeye Teşvik Et</h3>
                <p>Lengedu deneyimini arkadaşlarınla paylaş, İngilizce öğrenmeleri için ilk adımı sen at.</p>
              </div>
              <div class="feature col text-center">
                <img src="../assets/images/invite/winners.svg" width="100%">
                <h3 class="fs-5 text-body-emphasis">Hem Sen Hem Arkadaşın Kazansın</h3>
                <p>Davet ettiğin ve derslere başlayan her arkadaşın için indirimler ve hediyeler kazan, üstelik sınır yok.</p>
              </div>
            </div>
            
            <h3 class="text-secondary-emphasis mb-3 text-center">Arkadaşlarını Davet Et Ve Kazanmaya Başla</h3>
            <div class="row g-2 row-cols-1 row-cols-lg-2">
              <div class="col">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" tabindex="1" v-model="form.name" placeholder="">
                  <label for="name">Ad</label>
                </div>
                <div class="form-floating">
                  <input type="text" class="form-control" id="firendsSurname" tabindex="2" v-model="form.surname" placeholder="">
                  <label for="firendsSurname">Soyad</label>
                </div>
              </div>
              <div class="col">
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" tabindex="3" v-model="form.email" placeholder="">
                  <label for="firendsEmail">E-Posta Adresi</label>
                </div>
                <div class="input-group">
                  <div class="form-floating" style="max-width: 100px;">
                    <input type="text" class="form-control" tabindex="4" v-model="form.phoneCountryCode"  @keydown="checkDigit($event)" maxlength="4" placeholder="">
                    <label for="firendsPhoneCode">Ülke Kodu</label>
                  </div>
                  <div class="form-floating">
                    <input type="text" class="form-control" tabindex="5" v-model="form.phoneNumber" @keydown="checkDigit($event)" maxlength="10" placeholder="">
                    <label for="firendsPhone">Telefon Numarası</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-lg btn-primary mt-3" @click="SubmitForm()" :disabled="form.isLoading">Arkadaşını Davet Et</button>
            </div>
            
            <div class="row">
              <div class="col-lg-8">
                <div class="mt-5">
                  <h1>Arkadaşlarına Bir İyilik Yap</h1>
                  <p class="text-light-emphasis">Arkadaşlarını Lengedu'ya davet et, eğitimlerimize kayıt olup derslere başlayan her arkadaşın üzerinden <strong>1 ay eğitim hediye</strong> ve tüm Lengedu hizmetlerin de geçerli (Paket Değişikliği, Dakika Artırımı, İkinci Paket Alımı, Program Uzatma) <strong>%10 indirim çeki</strong> kazan. Üstelik arkadaşların da <strong>%10 indirimli</strong> kayıt olsun. </p>
                </div>
              </div>
              <div class="col-lg-4 text-center"><img src="../assets/images/invite/gift.svg" width="90%"></div>
            </div>


          </div>
        </div>
      </div>
    </div>



    

  </div>
  <FormDialog 
  :Title="dialog.title"
  :Content="dialog.content" 
  :Visible="dialog.dialogModalVisible"
  :ShowAcceptButton="false" 
  :ShowRefuseButton="false"
  @CloseModal="OpenCloseFormDialogModal($event)"
  
  />
  <FooterInPage />
</template>

<script>
import { mapActions } from "vuex";
import { LoadingToast,UpdateToast,ToastType } from "@/common/notification.js";
import {IsEmailValid} from "@/common/validation-email";
import FormDialog from "@/components/Modals/CustomDialogModal.vue";
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
export default {
  name: 'InvitePage',
  data() {
    return {
      form:{
        isLoading:false,
        name:'',
        surname:'',
        phoneCountryCode:'0090',
        phoneNumber:'',
        email:''
      },
      dialog:{
        title:'',
        content:'',
        dialogModalVisible:false,
      }
    };
  },
  components: {
    NavBar,
    FooterInPage,
    FormDialog
  },
  computed:{
  },
  methods: {
    ...mapActions(["RegisterReferenceForm"]),
    async SubmitForm(){
      if(this.form.phoneNumber.length<10 && !IsEmailValid(this.form.email))
      {
          this.dialog.content="Arkadaşınızın e-posta adresini ya da telefon numarasını giriniz.";
          this.dialog.title="Uyarı";
          this.OpenCloseFormDialogModal(true);
          return;
      }
      
      this.form.isLoading=true;
      let toastId = LoadingToast();
      let model = {
        name: this.form.name,
        surname: this.form.surname,
        phoneCountryCode: this.form.phoneCountryCode,
        phoneNumber: this.form.phoneNumber,
        email: this.form.email,
        utmSource: 'student.lengedu.com',
        utmMedium: '',
        utmContent: 'referans-form',
        utmCampaign: '',
        utmTerm: ''
      }
      await this.RegisterReferenceForm(model)
      .then((res)=>{
        this.form.isLoading=false;
        UpdateToast(toastId,res.description,ToastType().SUCCESS,100);
        this.clearForm();
      })
      .catch((err)=>{
        this.form.isLoading=false;
        UpdateToast(toastId,err.message,ToastType().ERROR);
      });
    },
    checkDigit(event){
      if (event.key.length === 1 && isNaN(Number(event.key))) {
        event.preventDefault();
      }
    },
    OpenCloseFormDialogModal(visible){
      this.dialog.dialogModalVisible=visible;
    },
    clearForm(){
      this.form.name='';
      this.form.surname='';
      this.form.phoneCountryCode='0090';
      this.form.phoneNumber='';
      this.form.email='';
    }
  },
  mounted: function() {
  }
}
</script>
<style>
</style>
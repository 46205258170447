import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import Home from '../views/Home.vue'
import Excercise from '../views/Excercise.vue'
import Test from '../views/Test.vue'
//import AboutUs from '../views/AboutUs.vue'
//import Faq from '../views/Faq.vue'
import MyPageDashboard from '../views/MyPage/Dashboard.vue'
import CoursesPage from '../views/Courses/Index.vue'
import CourseDetailPage from '../views/Courses/Detail.vue'
import CourseUnitPage from '../views/Courses/Units.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import RecoverPassword from '../views/RecoverPassword.vue'
import NotFound from '../views/NotFound.vue'
import InvitePage from '../views/Invite.vue'
import TrainingFile from '../views/TrainingFile.vue'
import OtherTools from '../views/OtherTools/Index.vue'
import RandomQuestion from '../views/OtherTools/RandomQuestion.vue'
//import Posts from '../views/Posts.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title:'Lengedu İngilizce Eğitim Platformu',requiresAuth: true},
  },
  {
    path: '/excercises',
    name: 'Excercise',
    component: Excercise,
    meta: {title:'Lengedu İngilizce Eğitim Platformu',requiresAuth: true},
  },
  {
    path: '/tests',
    name: 'Test',
    component: Test,
    meta: {title:'Lengedu İngilizce Eğitim Platformu',requiresAuth: true},
  },
  {
    path: '/invite',
    name: 'Invite',
    component: InvitePage,
    meta: {title:'Lengedu İngilizce Eğitim Platformu',requiresAuth: true},
  },
  {
    path: '/training',
    name: 'Training',
    component: TrainingFile,
    meta: {title:'Lengedu İngilizce Eğitim Platformu',requiresAuth: true},
  },
  {
    path: '/other-tools',
    name: 'OtherTools',
    component: OtherTools,
    meta: {title:'Lengedu İngilizce Eğitim Platformu',requiresAuth: true},
  },
  {
    path: '/other-tools/random-question-generator',
    name: 'RandomQuestion',
    component: RandomQuestion,
    meta: {title:'Lengedu İngilizce Eğitim Platformu',requiresAuth: true},
  },

  
  // {
  //   path: '/about-us',
  //   name: 'AboutUs',
  //   component: AboutUs
  // },
  // {
  //   path: '/faq',
  //   name: 'Faq',
  //   component: Faq
  // },
  
  {
    path: '/mypage',
    name: 'Dashboard',
    component: MyPageDashboard,
    meta: {requiresAuth: true},
  },
  {
    path: '/courses',
    name: 'Courses',
    component: CoursesPage,
    meta: {requiresAuth: true},
  },
  {
    path: '/courses/:unit',
    name: 'CourseUnitPage',
    component: CourseUnitPage,
    meta: {requiresAuth: true},
  },
 {
    path: '/courses/:unit/:id',
    name: 'CourseDetail',
    component: CourseDetailPage,
    meta: {requiresAuth: true},
  },
  {
    path: '/register',
    name: "RegisterPage",
    component: Register,
    meta: { title:'Lengedu İngilizce Eğitim Platformu', guest: true },
  },
  {
    path: '/login',
    name: "LoginPage",
    component: Login,
    meta: {title:'Lengedu İngilizce Eğitim Platformu',  guest: true },
  },
  {
    path: '/forgot-password',
    name: "ForgotPasswordPage",
    component: ForgotPassword,
    meta: { title:'Lengedu İngilizce Eğitim Platformu', guest: true },
  },
  {
    path: '/recover-password/:code',
    name: "RecoverPasswordPage",
    component: RecoverPassword,
    meta: { title:'Lengedu İngilizce Eğitim Platformu', guest: true },
  },
  {
    path: "/:notFound",
    name: "NotFoundPage",
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const title = to.meta.title
  if (title) {
    document.title = title
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next('/login');
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated && AnyLoginPages(to.matched[0].name)) {
      next('/');
    }else{  
      next();
    }
  }
  else {
    next();
  }
});

function AnyLoginPages(pageName){
  const loginPages=["LoginPage","RegisterPage","ForgotPasswordPage","RecoverPasswordPage"];
  let result = false;
  if(loginPages.find(x=>x===pageName)){
    result=true;
  }
  return result;
}

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.guest)) {
//     if (store.getters.isAuthenticated) {
//       next("/posts");
//       return;
//     }
//     next();
//   } else {
//     next();
//   }
// });

export default router

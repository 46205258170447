<template>
  <div>
    <div class="progress" style="height:25px">
        <div class="progress-bar bg-success fs-6" role="progressbar" :style="{'width': progressWith +'%'}" v-bind:aria-valuenow="progressNumber" aria-valuemin="0" aria-valuemax="100" v-html="progressNumber"></div>
    </div>
    <div style="height:80px;">
      <p class="text-center fs-4 mt-3 mb-3" v-if="question!=null" v-html="question.title"></p>
    </div>
    <div v-if="question!=null" class="row justify-content-md-center mt-3">
        <div class="col-sm-12 col-md-5 col-lg-4 mb-2">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-outline-primary text-start text-capitalize" @click="NextQuestion(0)" v-html="question.optionA"></button>
                <button type="button" class="btn btn-outline-primary text-start text-capitalize" @click="NextQuestion(1)" v-html="question.optionB"></button>
            </div>
        </div>
        <div class="col-sm-12 col-md-5 col-lg-4">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-outline-primary text-start text-capitalize" @click="NextQuestion(2)" v-html="question.optionC"></button>
                <button type="button" class="btn btn-outline-primary text-start text-capitalize" @click="NextQuestion(3)" v-html="question.optionD"></button>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
  import {DateFormat} from '@/common/datetime-helper';
  export default {
    name: "ExcerciseTest",
    props: {
      //[{id:'',sequence:1,question:'',audioiFileName:'',imageFileName:'',answers:['are','am','is','be'],right:0}]
      Visible:{
        type:Boolean,
        default:false
      },
      ExcerciseData:{
        type:Object,
      },
    },
    data() {
      return {
        questionNumber:0,
        userAnswers:[],
        beginTime:DateFormat(new Date().toUTCString(), 'yyyy-mm-dd hh:MM:ss', true),
        finishTime:''
      };
    },
    computed: {
      progressNumber() {
        if(this.ExcerciseData && this.ExcerciseData.length>0){
          let questionCount = this.ExcerciseData.length;
          if (this.questionNumber <= 0) {
              return 1;
          }
          else if ((questionCount - 1) <= this.questionNumber) {
              return questionCount;
          }
          else {
              return (this.questionNumber + 1);
          }
        }
        return 1;
      },
      progressWith: function () {
        if(this.ExcerciseData && this.ExcerciseData.length>0){
          return ((this.progressNumber * 100) / this.ExcerciseData.length).toFixed(1);
        }
        return 0;
      },
      question(){
        let result = null
        if(this.ExcerciseData && this.ExcerciseData.length>0){
          if(this.questionNumber>=0 && this.questionNumber<this.ExcerciseData.length){
            let data = this.ExcerciseData[this.questionNumber];
            result = {
              title : data.question,
              optionA : data.answers[0],
              optionB : data.answers[1],
              optionC : data.answers[2],
              optionD : data.answers[3]
            };
          }
        }
        return result;
      },
    },
    methods: {
      NextQuestion(value=0){
        if (this.questionNumber >= 0 && this.questionNumber < this.ExcerciseData.length) {
          let data = this.ExcerciseData[this.questionNumber];
          this.userAnswers.push({ Id: data.id, Answer: value });
        }
        this.questionNumber++;
        if (this.questionNumber >= this.ExcerciseData.length) {
          this.finishTime=DateFormat(new Date().toUTCString(), 'yyyy-mm-dd hh:MM:ss', true);
          this.$emit('SaveAnswer',{
            testId:null,
            beginDate:this.beginTime,
            finishDate:this.finishTime,
            answerContent:JSON.stringify(this.userAnswers)
          });
        }
      },
      init(){
        this.questionNumber=0;
        this.beginTime=DateFormat(new Date().toUTCString(), 'yyyy-mm-dd hh:MM:ss', true);
        this.finishTime='';
        this.userAnswers=[];
      }
    },
    watch: {
      Visible:function(){
        this.init();
      },
      ExcerciseData:function(){
        this.init();
      }
    }
  };
  </script>
  
  <style>
  </style>
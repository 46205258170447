<template>
  <div class="modal fade" tabindex="-1" id="ModalMyProfile" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Profilim</h1>
          <button type="button" class="btn-close" @click="CloseModalMyProfileModal()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-3 text-center border-end">
              <img src="../../assets/images/profile/default.png" class="img-thumbnail rounded-circle mb-3">
              <h5>{{ formProfile.name }} {{ formProfile.surname }}</h5>
              <h4>{{ formProfile.refenceNumber }}</h4>
            </div>
            <div class="col-lg-9">
              <div class="row g-3">
                <div class="col-6">
                  <label class="form-label" for="email">E-Posta</label>
                  <input type="text" class="form-control" id="email" v-model="formProfile.email" autocomplete="email" disabled>
                </div>

                <div class="col-6">
                  <label class="form-label" for="phone">Telefon</label>
                  <input type="text" class="form-control" id="phone" v-model="formProfile.phone" autocomplete="tel" disabled>
                </div>

                <div class="col-4">
                  <label class="form-label" for="dateOfBirth">Doğum Tarihi</label>
                  <input type="text" class="form-control" id="dateOfBirth" v-bind:value="DateOfBirth" disabled>
                </div>

                <div class="col-4">
                  <label  class="form-label" for="gender">Cinsiyet</label>
                  <input type="text" class="form-control" id="gender" v-bind:value="Gender" disabled>
                </div>

                <div class="col-4">
                  <label  class="form-label" for="timeOffset">Saat Dilimi</label>
                  <input type="text" class="form-control" id="timeOffset" v-model="formProfile.timeOffset" disabled>
                </div>
                
                <div class="col-12">
                  <div class="input-group">
                    <input type="password" class="form-control" id="oldPassword" v-model="formPassword.oldPassword" placeholder="Mevcut Parola" autocomplete="new-password">
                    <input type="password" class="form-control" id="newPassword" v-model="formPassword.newPassword" placeholder="Yeni Parola" autocomplete="new-password">
                    <input type="password" class="form-control" id="newPasswordConfirmation" v-model="formPassword.newPasswordConfirmation" placeholder="Yeni Parola Onay" autocomplete="current-password">
                    <button class="btn btn-primary" @click="passwordSubmit()" :disabled="formPassword.isLoading"><i class="bi bi-floppy"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { mapGetters,mapActions } from "vuex";
import {DateFormat} from "@/common/datetime-helper";
import {GenderText} from "@/common/enum-helper";
import { LoadingToast,UpdateToast,ErrorToast,ToastType } from "@/common/notification.js";
import {IsPasswordValid} from "@/common/validation-password";
import bootstrapBundle from 'bootstrap/dist/js/bootstrap.bundle';
  export default {
    name: "MyProfile",
    props: {
      Visible:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
        formProfile: {
          profileImage: "",
          email: "",
          phone:"",
          dateOfBirth:"",
          gender:"",
          timeOffset:"",
          refenceNumber:"",
          name:"",
          surname:""
        },
        formPassword:{
          oldPassword:"",
          newPassword:"",
          newPasswordConfirmation:"",
          isLoading:false
        },
        modalMyProfile:null
      };
    },
    computed: {
      ...mapGetters({ Profile: "StateProfile" }),
      DateOfBirth() {
        if (this.formProfile.dateOfBirth === '')
            return '';

        return DateFormat(this.formProfile.dateOfBirth,'dd.mm.yyyy');
      },
      Gender(){
        if (this.formProfile.gender === '')
            return '';

        return GenderText(this.formProfile.gender);
      }
    },
    methods: {
      ...mapActions(["ProfileChangePasswordSave","ProfileInfo"]),
      getProfile(){
        if(this.Profile){
          this.formProfile.profileImage=  "";
          this.formProfile.email= this.Profile.email;
          this.formProfile.phone=  this.Profile.phoneNumber;
          this.formProfile.dateOfBirth=  this.Profile.birthDate;
          this.formProfile.gender=  this.Profile.gender;
          this.formProfile.timeOffset=  this.Profile.timeOffset;
          this.formProfile.refenceNumber=  this.Profile.refenceNumber;
          this.formProfile.name=  this.Profile.name;
          this.formProfile.surname=  this.Profile.surname;
        }
      },
      async passwordSubmit() {
        if(!this.isPasswordFormValid()){
          return;
        }
        let toastId = LoadingToast();
        this.formPassword.isLoading = true;
        const passwordForm = {
          "oldPassword": this.formPassword.oldPassword,
          "password": this.formPassword.newPassword,
        };
        await this.ProfileChangePasswordSave(passwordForm)
        .then((res)=>{
          UpdateToast(toastId,res.description,ToastType().SUCCESS);
          this.formPassword.isLoading=false;
          this.formPassword.oldPassword="";
          this.formPassword.newPassword="";
          this.formPassword.newPasswordConfirmation="";
        })
        .catch((err)=>{
          UpdateToast(toastId,err.message,ToastType().ERROR);
          this.formPassword.isLoading=false;
        });
      },
      isPasswordFormValid(){
        if (this.formPassword.oldPassword.length < 4) {
            ErrorToast('Mevcut parolanızı giriniz.');
            return false;
        }
        if (this.formPassword.newPassword.length < 4 || this.formPassword.newPassword.length>8) {
            ErrorToast('Parolanız en az 4 en fazla 8 karakterden oluşmalıdır.');
            return false;
        }
        if (!IsPasswordValid(this.formPassword.newPassword)) {
            ErrorToast('Hatalı parola girdiniz.');
            return false;
        }
        if (this.formPassword.newPassword !== this.formPassword.newPasswordConfirmation) {
            ErrorToast('Parolanızı onaylayınız.');
            return false;
        }

        return true;
      },
      CloseModalMyProfileModal(){
        this.modalMyProfile.hide();
        this.$emit("CloseModalMyProfile", false);
      }
    },
    watch: {
      Visible(value){
        if(value){
          this.ProfileInfo().then(()=>{
            this.getProfile();
          });
          this.modalMyProfile.show();
        }
      }
    },
    mounted: function() {
      this.modalMyProfile = new bootstrapBundle.Modal(document.getElementById('ModalMyProfile'),{backdrop: 'static',keyboard: false});
    }
  };
  </script>
  
  <style>
  </style>
<template>
  <NavBar />
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-11">
        <div class="container">
          <div class="row mb-2">
            <div class="col-6 text-start">
              <h5><i class="bi bi-bank"></i> Genel İngilizce</h5>
            </div>
            <div class="col-6 text-end">
              <button class="btn btn-sm">Daha Fazla Göster</button>
            </div>
          </div>
          <div class="row mb-4">
            <router-link  v-for="b in books" :key="b" :to="{path:'/courses/'+b.id}" class="col-sm-12 col-md-6 col-lg-2 mb-2 text-decoration-none">
              <div class="card course-card">
                <img :src="require('@/assets/images/'+b.image)">
                <div class="card-body">
                  <h5 class="card-title small" v-html="b.title"></h5>
                  <p class="card-text small" v-html="b.description"></p>
                </div>
                <div class="card-footer bg-white small">
                  <span class="badge badge-color-yellow  rounded-pill" v-html="b.level"></span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterInPage />
</template>

<script>
import NavBar from '@/components/Shared/NavBar.vue'
import FooterInPage from '@/components/Shared/FooterInPage.vue'
import bookList from '@/mockData/bookdata'
export default {
  name: 'CoursesPage',
  components: {
    NavBar,
    FooterInPage,
  },
  data() {
    return {
    };
  },
  computed:{
      books(){
        return bookList.bookList;
      }
    },
  methods: {
    
  },
}
</script>
<style>
    .course-card{
        box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
        cursor: pointer;
      }
  
      .course-card:hover{
        transform: scale(1.05);
        box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
      }
  
        @media (max-width: 576px) {
          .mobile-card-container > .row {
            overflow-x: auto;
          }
  
      }
      </style>
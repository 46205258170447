<template>
    <div class="border rounded p-2" style="margin-bottom:5px;">
        <div v-if="editor">
            <div class="d-flex justify-content-center mb-3">
                <div class="btn-group" role="group">
                    <button class="btn btn-sm btn-outline-secondary" @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'active': editor.isActive('bold') }">
                        <i class="bi bi-type-bold"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-secondary" @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'active': editor.isActive('italic') }">
                        <i class="bi bi-type-italic"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-secondary" @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'active': editor.isActive('strike') }">
                        <i class="bi bi-type-strikethrough"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-secondary" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'active': editor.isActive('bulletList') }">
                        <i class="bi bi-list-ul"></i>
                    </button>
                    <button class="btn btn-sm btn-outline-secondary">
                        {{ editor.storage.characterCount.characters() }} / {{ limit }}
                    </button>
                    
                </div>
            </div>
        </div>
        <div style="height: 120px; overflow: auto;">
            <editor-content class="editor" :editor="editor" />
        </div>
    </div>
    <button class="btn btn-primary btn-sm me-1" @click="SaveNote()">Kaydet</button>
  </template>
  
  <script>
  import CharacterCount from '@tiptap/extension-character-count'
  import { Editor, EditorContent } from '@tiptap/vue-3'
  import StarterKit from '@tiptap/starter-kit'
  
  export default {
    name:"TipTapEditor",
    emits: ["SaveLessonNote"],
    components: {
      EditorContent,
    },
    props:{
      Description:{
        type:String,
        default:null
      },
    },
    data() {
      return {
        editor: null,
        limit: 750,
      }
    },
    methods:{
      SaveNote(){ 
        this.$emit("SaveLessonNote", this.editor.getHTML());
      }
    },
    mounted() {
      this.editor = new Editor({
        content: this.Description,
        extensions: [
          StarterKit,
          CharacterCount.configure({limit: this.limit})
        ],
      })
    },
  
    beforeUnmount() {
      this.editor.destroy()
    },
    watch: {
      Description(value){
        if(value){
          this.editor.commands.setContent(value);
        }
      }
    },
  }
  </script>
  <style scoped>
    .editor::v-deep(.ProseMirror) {
        outline: none;
        min-height: 100px;
    }
  </style>
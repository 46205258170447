const GenderText = (value) => {
    switch (value) {
        case 1:
            return 'Erkek';
        case 2:
            return 'Kadın';
        default:
            return '';
    }
}

const LevelText = (value)=>{
    let result = null;
    switch (value) {
        case 1:
            result = 'A1 – Başlangıç';
            break;
        case 2:
            result = 'A2 - Temel';
            break;
        case 3:
            result = 'B1 - Orta Öncesi';
            break;
        case 4:
            result = 'B2 - Orta';
            break;
        case 5:
            result = 'C1 - Orta Üstü';
            break;
        case 6:
            result = 'C2 - İleri';
            break;
        default:
            result=null;
            break;
    }
    return result;
}

const ResultPuanText = (value) => {
    switch (value) {
        case 1:
            return 'e';
        case 2:
            return 'd';
        case 3:
            return 'c';
        case 4:
            return 'b';
        case 5:
            return 'a';
        default:
            return '-';
    }
}

const WeekDayText = (value) => {
    switch (value) {
        case 3:
            return 'Pzt, Çar, Cum';
        case 2:
            return 'Sal, Per';
        default:
            return '';
    }
}
export { GenderText,LevelText,ResultPuanText,WeekDayText }
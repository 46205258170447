<template>
    <div>
        <div v-if="BookTests!=null && BookTests.length>0">
            <div v-if="isShowResult">
                <div class="row justify-content-center mt-3 mb-2">
                    <div class="col-sm-12 col-md-6 col-lg-6 text-center text-white">
                        <div class="small-box bg-success p-2 rounded mb-1">
                            <div class=" fs-5" ><i class="bi bi-check2"></i> Doğru Cevap</div>
                            <div class="fs-2" v-html="totalCorrect"></div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 text-center text-white">
                        <div class="small-box bg-danger p-2 rounded mb-1">
                            <div class=" fs-5" ><i class="bi bi-x"></i> Yanlış Cevap</div>
                            <div class="fs-2" v-html="totalInCorrect"></div>
                        </div>
                    </div>
                </div>
                <div class="row border-bottom pt-3 pb-3" v-for="(q,index) in BookTests" :key="q">
                    <div class="col-lg-1 text-center"><h3 v-html="(index+1)+'.'"></h3></div>
                    <div class="col-lg-10">
                        <p v-html="q.question"></p>
                        <div class="row justify-content-md-center mt-3">
                            <div class="col-lg-6">
                                <div class="border p-2 rounded-3 mb-2" :class="[OptionColor(0,q)]">
                                    <table><tr><td class="align-top fw-medium" style="width:25px">A)</td><td v-html="q.optionA"></td></tr></table>
                                </div>
                                <div class="border p-2 rounded-3 mb-2" :class="[OptionColor(1,q)]">
                                    <table><tr><td class="align-top fw-medium" style="width:25px">B)</td><td v-html="q.optionB"></td></tr></table>
                                </div>
                                <div class="border p-2 rounded-3 mb-2" :class="[OptionColor(2,q)]">
                                    <table><tr><td class="align-top fw-medium" style="width:25px">C)</td><td v-html="q.optionC"></td></tr></table>
                                </div>
                                <div class="border p-2 rounded-3 mb-2" :class="[OptionColor(3,q)]">
                                    <table><tr><td class="align-top fw-medium" style="width:25px">D)</td><td v-html="q.optionD"></td></tr></table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="progress" style="height:25px">
                    <div class="progress-bar bg-success fs-6" role="progressbar" :style="{'width': progressWith +'%'}" v-bind:aria-valuenow="progressNumber" aria-valuemin="0" aria-valuemax="100" v-html="progressNumber"></div>
                </div>
                <div style="display:table; width: 100%;">
                    <div style="height:150px; display: table-cell; vertical-align: middle;">
                        <p class="text-center fs-4 mt-3 mb-3" v-if="question!=null" v-html="question.title"></p>
                    </div>
                </div>
                <div v-if="question!=null" class="row justify-content-md-center mt-3">
                    <a href="javascript:;"></a>
                    <div class="col-lg-6">
                        <div class="option border p-2 rounded-3 mb-2" @click="NextQuestion(0)">
                            <table><tr><td class="align-top fw-medium" style="width:25px">A)</td><td class="text-capitalize" v-html="question.optionA"></td></tr></table>
                        </div>
                        <div class="option border p-2 rounded-3 mb-2" @click="NextQuestion(1)">
                            <table><tr><td class="align-top fw-medium" style="width:25px">B)</td><td class="text-capitalize" v-html="question.optionB"></td></tr></table>
                        </div>
                        <div class="option border p-2 rounded-3 mb-2" @click="NextQuestion(2)">
                            <table><tr><td class="align-top fw-medium" style="width:25px">C)</td><td class="text-capitalize" v-html="question.optionC"></td></tr></table>
                        </div>
                        <div class="option border p-2 rounded-3 mb-2" @click="NextQuestion(3)">
                            <table><tr><td class="align-top fw-medium" style="width:25px">D)</td><td class="text-capitalize" v-html="question.optionD"></td></tr></table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center pt-5">
            <span class="fs-5">Bu konunun testleri hazırlanmaktadır.</span>
        </div>
    </div>
</template>
<script>
import {mapActions } from "vuex";
import {DateFormat} from '@/common/datetime-helper';
export default {
    name: "TabTestSubject",
    props: {
      //[{"id":1,"sequence": 1,"question": "How are you today?","optionA": "You’re fine","optionB": "I’m fine","optionC": "I’m 32","optionD": "I’m Turkish"}]
      BookTests:{
        type:Object,
      },
      BookId:{
        type:String,
        default:null
      }
    },
    
    data() {
        return {
            questionNumber:0,
            userAnswers:[],
            beginTime:DateFormat(new Date().toUTCString(), 'yyyy-mm-dd hh:MM:ss', true),
            finishTime:'',
            isShowResult:false,
            
            totalDuration:'00:00:00',
            totalInCorrect:0,
            totalCorrect:0,
        }
    },
    computed: {
        progressNumber() {
            if(this.BookTests && this.BookTests.length>0){
            let questionCount = this.BookTests.length;
            if (this.questionNumber <= 0) {
                return 1;
            }
            else if ((questionCount - 1) <= this.questionNumber) {
                return questionCount;
            }
            else {
                return (this.questionNumber + 1);
            }
            }
            return 1;
        },
        progressWith: function () {
            if(this.BookTests && this.BookTests.length>0){
            return ((this.progressNumber * 100) / this.BookTests.length).toFixed(1);
            }
            return 0;
        },
        question(){
            let result = null
            if(this.BookTests && this.BookTests.length>0){
                if(this.questionNumber>=0 && this.questionNumber<this.BookTests.length){
                    let data = this.BookTests[this.questionNumber];
                    result = {
                        title : data.question,
                        optionA : data.optionA,
                        optionB : data.optionB,
                        optionC : data.optionC,
                        optionD : data.optionD
                    };
                }
            }
            return result;
        },
    },
    methods: {
        ...mapActions(["SaveAnswerBookTest"]),
        NextQuestion(value=0){
            if (this.questionNumber >= 0 && this.questionNumber < this.BookTests.length) {
                let data = this.BookTests[this.questionNumber];
                this.userAnswers.push({ Id: data.id, Answer: value });
            }
            this.questionNumber++;
            if (this.questionNumber >= this.BookTests.length) {
                this.finishTime=DateFormat(new Date().toUTCString(), 'yyyy-mm-dd hh:MM:ss', true);
                this.SaveAnswer({
                    bookId:this.BookId,
                    beginDate:this.beginTime,
                    finishDate:this.finishTime,
                    answerContent:JSON.stringify(this.userAnswers)
                });
                this.QuestionControl();
                this.isShowResult=true;
            }
        },
        async SaveAnswer(form){    
            await this.SaveAnswerBookTest(form)
            .then(()=>{})
            .catch(()=>{});
        },
        OptionColor(optionIndex,question) {
            if(!this.userAnswers || this.userAnswers.length==0) return;

            let id = question.id;
            let right = question.right;
            let answer = this.userAnswers.find(x=>x.Id===id).Answer;
            
            let classStr = '';
            if ((parseInt(optionIndex,10) === parseInt(answer,10) && parseInt(answer,10) === parseInt(right,10)) || (parseInt(right,10) === parseInt(optionIndex,10))){
                classStr = 'bg-success text-white';
            }
            else if (parseInt(optionIndex,10) === parseInt(answer,10)){
                classStr = 'bg-danger text-white';
            }
            else{
                classStr = 'bg-light';
            }
            return classStr;
            
        },
        QuestionControl(){
            this.totalCorrect = 0;
            this.totalInCorrect = 0;
            for (let i = 0; i < this.BookTests.length; i++) {
                let data = this.BookTests[i];
                let answer = this.userAnswers.find(x=>x.Id===data.id).Answer;  
                if(data.right===answer){
                    this.totalCorrect++;
                }else{
                    this.totalInCorrect++;
                }
            }
        },
    },
    watch: {
        Tests:function(){
        this.questionNumber=0;
        this.beginTime=DateFormat(new Date().toUTCString(), 'yyyy-mm-dd hh:MM:ss', true);
        this.finishTime='';
      }
    },
};
</script>
    
<style>
    .option:hover{
        border:1px solid #58b948;
        background-color: #58b948;
        color:#FFF;
        cursor: pointer;
    }
</style>
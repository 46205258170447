<template>
  <div class="modal fade" tabindex="-1" v-bind:id="ModalName" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div v-if="showInterestedMessage" class="text-center">
            <img src="/img/support.f3d579d6.svg" class="mt-5" style="width: 250px;">
            <p class="fs-5 mt-5 mb-5">Eğitim koçunuz sizinle en kısa sürede iletişime geçecektir.</p>
          </div>
          <div v-else>
            <div class="clearfix" v-if="notification.isShowCloseButton">
              <button type="button" class="btn-close float-end m-2" @click="RemindMeLater()"></button>
            </div>  
            <div v-html="notification.content"></div>
            <div class="clearfix p-2" v-if="notification.isShowRemindMeLaterButton || notification.isShowInterestedButton || notification.isShowNotInterestedButton">
              <button class="btn btn-sm btn-secondary float-start" v-if="notification.isShowNotInterestedButton" @click="NotInterested()" :disabled="notInterestedButtonLoading">İlgilenmiyorum</button>
              <button class="btn btn-sm btn-primary float-end" v-if="notification.isShowRemindMeLaterButton" @click="Interested()" :disabled="interestedButtonLoading">İlgileniyorum</button>
              <button class="btn btn-sm btn-secondary float-end me-2" v-if="notification.isShowInterestedButton" @click="RemindMeLater()">Daha sonra hatırlat</button> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {GetCookie, SetCookie} from "@/common/cookie-helper";
  import { mapActions } from "vuex";  
  import bootstrapBundle from 'bootstrap/dist/js/bootstrap.bundle';
  export default {
    name: "NotificationModal",
    components:{
    },
    props: {
      ModalName:{
        type:String,
        default:'NotificationModal'
      },
    },
    data() {
      return {
        modalElement:null,
        notificationList:[],
        index:0,
        interestedButtonLoading:false,
        notInterestedButtonLoading:false,
        showInterestedMessage:false
      };
    },
    computed: {
      notification(){
        let result = {
          id : '',
          content : '',
          isShowCloseButton : false,
          isShowInterestedButton : false,
          isShowNotInterestedButton : false,
          isShowRemindMeLaterButton : false,
          remindMeLaterDuration:0,
          cookieKey:''
        };
       
        if(this.notificationList.length>0){
          let selectNotification = this.notificationList[this.index];
          result.id = selectNotification.id;
          result.content  =  selectNotification.content;
          result.isShowCloseButton = selectNotification.isShowCloseButton;
          result.isShowCloseButton = selectNotification.isShowCloseButton;
          result.isShowInterestedButton = selectNotification.isShowInterestedButton;
          result.isShowNotInterestedButton = selectNotification.isShowNotInterestedButton;
          result.isShowRemindMeLaterButton = selectNotification.isShowRemindMeLaterButton;
          result.remindMeLaterDuration = selectNotification.remindMeLaterDuration;
          result.cookieKey = selectNotification.cookieKey;
        }
        return result;
      }
    },
    methods: {
      ...mapActions(["GetNotification","InterestedNotification","NotInterestedNotification"]),
      CloseModal(){
        this.modalElement.hide();
      },
      ShowModal(){
        this.modalElement.show();
      },
      NextNotification(){
        let nextIndex = this.index + 1;
        if(nextIndex>=(this.notificationList.length))
        {
          this.CloseModal();
          return;
        }else{
          this.index = nextIndex;
        }
      },
      RemindMeLater(){
        let selected = this.notification;
        if(selected.id!=''){
          SetCookie(selected.cookieKey,'true',selected.remindMeLaterDuration);
        }
        this.NextNotification();
      },
      async Interested(){
        let mainThis = this;
        this.showInterestedMessage=true;
        this.interestedButtonLoading=true;
        await this.InterestedNotification({id:this.notification.id})
        .then(()=>{
          let selected = this.notification;
          if(selected.id!=''){
            SetCookie(selected.cookieKey,'true',15*24*60);
          }
          setTimeout(() => {
            mainThis.NextNotification();
            mainThis.showInterestedMessage=false;
          }, 3000);
          
          this.interestedButtonLoading=false;
        })
        .catch(()=>{
          this.interestedButtonLoading=false;
        });
      },
      async NotInterested(){
        this.notInterestedButtonLoading=true;
        await this.NotInterestedNotification({id:this.notification.id})
        .then(()=>{
          let selected = this.notification;
          if(selected.id!=''){
            SetCookie(selected.cookieKey,'true',15*24*60);
          }
          this.NextNotification();
          this.notInterestedButtonLoading=false;
        })
        .catch(()=>{
          this.notInterestedButtonLoading=false;
        });
      },
      CheckNotificationForCookie(notifications){
        let result = [];
        for (let index = 0; index < notifications.length; index++) {
          const n = notifications[index];
          var cookie = GetCookie(n.cookieKey);
          if(cookie==''){
            result.push(n);
          }
        }
        return result;
      },
      async Init(){
        await this.GetNotification({viewZone:5})
        .then((res)=>{
          this.notificationList = this.CheckNotificationForCookie(res);
          if(this.notificationList.length>0){
            this.ShowModal();
          }
        })
        .catch(()=>{});
      }
    },
    watch: {
      
    },
    mounted: function() {
      this.Init();
      let elementName = this.ModalName;
      this.modalElement = new bootstrapBundle.Modal(document.getElementById(elementName),{backdrop: 'static',keyboard: false});
    }
  };
  </script>
  
  <style>
  </style>
<template>
  <div>
    <p class="text-center text-black-50 mt-3"><small>Lengedu İngilizce Eğitim v{{appVersion}}-Beta</small></p>
  </div>
</template>
<script>
import packageInfo from '../../../package.json';
export default {
  name: "VersionNumber",
  computed: {
    appVersion : function(){
        return packageInfo.version;
      }
  },
  methods: {

  },
};
</script>
  
<style></style>